import React, { useEffect } from 'react'
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Trafficking = () => {
    const navigate = useNavigate();
    // useEffect(() => {
    //     if (
    //         !localStorage.getItem("auth_user")
    //     ) {
    //         navigate("/login");
    //     }
    // }, []);

    const breadCrumb = [
        {
            text: "Home",
            url: "",
        },
        {
            text: "Slavery & Human Trafficking",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <div>
            <Helmet>
                <title>W-Hanson UK’s Fight to End Human Trafficking and Slavery</title>
                <meta NAME="Description" Content="At W-Hanson UK, preventing slavery and human trafficking is a top priority, reflecting our dedication to ethical business practices." />
            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--my-account">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-section--custom">
                        <div className="container">
                            <div className="ps-section__header">
                                <h1 className="text-center " style={{ fontWeight: "550" }}>Slavery & human trafficking statement 2016</h1>
                            </div>
                            <div className="ps-section__content term-section mt-40">

                                <p>
                                    This statement is made on behalf of W.Hanson Limited (“W Hanson”) pursuant to section 54(1) of the Modern Slavery Act 2015 (the “Act”) and constitutes its slavery and human trafficking statement. W Hanson is a regional timber and builders merchants business serving customers across the south east of England including London and the Home Counties.
                                </p>
                                <p>W Hanson is committed to meeting the aims of the Act and to having a work environment that is free from human trafficking, slavery, servitude and forced or compulsory labour. We strongly oppose slavery and human trafficking in our supply chains and in any part of our business. </p>
                                <p>The employment and procurement practices which we operate contribute to identify, assess and monitor potential risk areas within our business and mitigate the risk of slavery and human trafficking occurring in our engagement of external contractors and suppliers. To be trusted to do the right thing is one of our core values. We would never knowingly engage with suppliers or contractors involved in slavery or human trafficking. </p>


                                <h3>Employment practices  </h3>
                                <p>W Hanson rigorously checks that all of its employees have the right to work in the UK and are paid at or above the minimum wage. The same rigour is applied to ensure that agency workers are subject to the same checks. As well as setting out the rights and benefits which are available to W Hanson employees, the employee handbooks and individual contracts of employment make it clear what actions and behaviours are expected of them.</p>

                                <h3>Procurement practices </h3>
                                <p>Where possible, we have built and maintained longstanding relationships with trusted suppliers/contractors, making clear our expectations of business behaviour. As part of our procurement process, any potential contractor or supplier is required to confirm that they comply with all applicable laws and regulations, including the Act.</p>
                                <p>Their responses are taken into consideration when short-listing and any subsequent failure to comply or to make any changes to ensure compliance may result in the termination of their engagement. </p>
                                <p>We are currently reviewing our procurement process and contract templates (including purchase order terms) to seek to introduce further measures to ensure compliance with the Act including: </p>
                                <ol>
                                    <li>ensuring contractual provisions are in place so that our suppliers/ contractors (and their suppliers/contractors) abide by applicable laws and regulations including more specifically the Act;</li>
                                    <li>express warranties with respect to their and their supplier’s/contractors compliance with the Act;</li>
                                    <li>right to audit and request compliance-related information; and right to terminate for failure to comply with law and regulations including the Act.</li>
                                </ol>
                                <h3>Communication </h3>
                                <p>
                                    This statement is available to all our staff to ensure a high level of understanding of the risks of modern slavery and human trafficking and we will provide training to staff as and when it is deemed appropriate and necessary.</p>
                                <p>We also aim to promote a company culture where the reporting of any concern is encouraged and we will be reviewing our whistleblowing Policy to reflect this. </p>

                                <h3>Due Diligence Process  </h3>
                                <p>W Hanson has and will continue to review its own operations and supply chain to evaluate human trafficking and slavery risks. At present, following internal review, W Hanson has uncovered no evidence of slavery and human trafficking within its own operations and those of its suppliers and contractors and therefore considers the risk to be low.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>

        </div>
    )
}

export default Trafficking