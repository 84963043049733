import React from "react";
import { Link } from "react-router-dom";
import svg from "../../assets/static/img/wave(7).svg";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import config from "../../config";

const Panel = ({ verticalTab }) => {
    const Cart = JSON.parse(localStorage.getItem("items"));

    useEffect(() => {
        if (verticalTab == 1) {
            onGetData();
        }
    }, [verticalTab]);

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const onGetData = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("user_id", localStorage.getItem("auth_id"));
        try {
            await axios({
                method: "post",
                url: `${config.apiUrl}/order`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                setData(res.data);
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const totalItems = data.reduce(
        (total, obj) => total + obj.items.data?.length,
        0
    );

    return (
        <>
            {isLoading && <Loader />}
            <div className="ps-page__content">
                <div className="ps-form--account-setting">
                    <div className="ps-form__header">
                        <h3>Dashboard</h3>
                    </div>
                    <div className="mt-45">
                        <div className="row">
                            <div className="col-4">
                                <div className="bg-grad-1 box-shadow-box">
                                    <div className="bd-product-dash">
                                        <h1 className="text-white">
                                            {Cart != null ? Cart?.length : 0}{" "}
                                            Products
                                        </h1>
                                        <p className="text-white">
                                            in your cart
                                        </p>
                                    </div>
                                    <img src={svg} height="60" id="path" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="bg-grad-2 box-shadow-box">
                                    <div className="bd-product-dash">
                                        <h1 className="text-white">
                                            {" "}
                                            {data?.length} Orders
                                        </h1>
                                        <p className="text-white">
                                            in your purchase history
                                        </p>
                                    </div>
                                    <img src={svg} height="60" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="bg-grad-1 box-shadow-box">
                                    <div className="bd-product-dash">
                                        <h1 className="text-white">
                                            {" "}
                                            {totalItems} Products
                                        </h1>
                                        <p className="text-white">
                                            you ordered
                                        </p>
                                    </div>
                                    <img src={svg} height="60" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Panel;
