import React, { useState } from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import { Input, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader';
import config from '../../config';

const GuestCheckout = () => {
    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Guest Checkout",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [isLoading, setLoading] = useState(false)

    const navigate = useNavigate();

    const onGuestRegister = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile", mobile);
        formData.append("type", 1);
        try {
            const response = await axios({
                method: "post",
                url: `${config.apiUrl}/auth/signup`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                if (response.data.result == false) {
                    notification.open({
                        message: (
                            <div className="d-flex">
                                <div className="pr-20">
                                    <i className="fa fa-exclamation-circle text-danger" style={{ color: "#4bb543" }}></i>
                                </div>
                                <div>User Already Exist</div>
                            </div>
                        ),
                        description: "Please try using different Email Address",
                    });
                } else {
                    notification.open({
                        message: (
                            <div className="d-flex">
                                <div className="pr-20">
                                    <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                                </div>
                                <div>Registration Done Successfully</div>
                            </div>
                        ),
                        description: "You are Logged in as Guest User",
                    });
                    localStorage.setItem("auth_id", response.data.user_id.id);
                    localStorage.setItem("auth_name", response.data.user_id.name);
                    localStorage.setItem("auth_type", 'guest');

                    navigate("/checkout");
                }

                setLoading(false);
            });
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--my-account">
                    <BreadCrumb breacrumb={breadCrumb} />

                    <div className="mb-70 mt-70 bg-white">
                        <div className="container">
                            <form onSubmit={onGuestRegister}
                                className="ps-form--account pt-0"
                            >
                                <div className="ps-tab active border-radius3 bd-login-page">
                                    <div className="ps-form__content p-5 ">
                                        <h2 className="pb-20 text-center">Guest Checkout</h2>
                                        <div className="form-group form-forgot">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Full Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group form-forgot">
                                            <Input
                                                className="form-control"
                                                type="email"
                                                placeholder="Enter Email Address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group form-forgot ">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                                value={mobile}
                                                onChange={(e) => setMobile(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group pb-20">
                                            <div className="ps-checkbox">
                                                <input
                                                    className="form-control"
                                                    type="checkbox"
                                                    id="remember-me"
                                                    name="remember-me"
                                                    required
                                                />
                                                <label htmlFor="remember-me" style={{ fontSize: "13px" }}>
                                                    By signing up you agree to our terms and conditions.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group submit">
                                            <button
                                                type="submit"
                                                className="ps-btn ps-btn--fullwidth bg-blue color-white"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                        <div className="mt-20 text-center">
                                            <p className="mb-0">Already have an account?</p>
                                            <Link
                                                to="/users/login"
                                                className="color-blue"
                                                style={{ fontWeight: "600" }}
                                            >
                                                Log In
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </PageContainer>
        </div>
    )
}

export default GuestCheckout