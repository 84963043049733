import React, { useEffect } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Terms and Conditions",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  return (
    <div>
      <Helmet>
        <title>W-Hanson UK's Privacy Policy</title>
        <meta name="description" content="Learn how W-Hanson UK safeguards your information. Explore our comprehensive privacy policy to understand our commitment to data security and transparency." />
      </Helmet>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-section--custom">
            <div className="container">
              <div className="ps-section__header">
                <h1 className="text-center " style={{ fontWeight: "550" }}>
                  Data Protection Policy
                </h1>
              </div>
              <div className="ps-section__content term-section bd-privacy mt-40">
                <h3>INTRODUCTION</h3>
                <p>
                  We may have to collect and use information about people with
                  whom we work. This personal information must be handled and
                  dealt with properly, however it is collected, recorded and
                  used, and whether it be on paper, in computer records or
                  recorded by any other means.
                </p>
                <p>
                  We regard the lawful and correct treatment of personal
                  information as very important to our successful operation and
                  to maintaining confidence between us and those with whom we
                  carry out business. We will ensure that we treat personal
                  information lawfully and correctly.
                </p>
                <p>
                  To this end we fully endorse and adhere to the principles of
                  the General Data Protection Regulation (GDPR).
                </p>
                <p>
                  This policy applies to the processing of personal data in
                  manual and electronic records kept by us in connection with
                  our human resources function as described below. It also
                  covers our response to any data breach and other rights under
                  the GDPR.
                </p>
                <p>
                  This policy applies to the personal data of job applicants,
                  existing and former employees, apprentices, volunteers,
                  placement students, workers and self-employed contractors.
                  These are referred to in this policy as relevant individuals.
                </p>

                <h3>DEFINITIONS</h3>
                <p>
                  “Personal data” is information that relates to an identifiable
                  person who can be directly or indirectly identified from that
                  information, for example, a person’s name, identification
                  number, location, online identifier. It can also include
                  pseudonymised data.
                </p>
                <p>
                  “Special categories of personal data” is data which relates to
                  an individual’s health, sex life, sexual orientation, race,
                  ethnic origin, political opinion, religion, and trade union
                  membership. It also includes genetic and biometric data (where
                  used for ID purposes).
                </p>
                <p>
                  “Criminal offence data” is data which relates to an
                  individual’s criminal convictions and offences.
                </p>
                <p>
                  “Data processing” is any operation or set of operations which
                  is performed on personal data or on sets of personal data,
                  whether or not by automated means, such as collection,
                  recording, organisation, structuring, storage, adaptation or
                  alteration, retrieval, consultation, use, disclosure by
                  transmission, dissemination or otherwise making available,
                  alignment or combination, restriction, erasure or destruction.
                </p>
                <h3>DATA PROTECTION PRINCIPLES</h3>
                <p>
                  Under GDPR, all personal data obtained and held by us must be
                  processed according to a set of core principles. In accordance
                  with these principles, we will ensure that:
                </p>
                <ol>
                  <li>Processing will be fair, lawful and transparent</li>
                  <li>
                    Data be collected for specific, explicit, and legitimate
                    purposes
                  </li>
                  <li>
                    Data collected will be adequate, relevant and limited to
                    what is necessary for the purposes of processing
                  </li>
                  <li>
                    Data will be kept accurate and up to date. Data which is
                    found to be inaccurate will be rectified or erased without
                    delay
                  </li>
                  <li>
                    Data is not kept for longer than is necessary for its given
                    purpose
                  </li>
                  <li>
                    Data will be processed in a manner that ensures appropriate
                    security of personal data including protection against
                    unauthorised or unlawful processing, accidental loss,
                    destruction or damage by using appropriate technical or
                    organisation measures
                  </li>
                  <li>
                    We will comply with the relevant GDPR procedures for
                    international transferring of personal data
                  </li>
                </ol>

                <h3>TYPES OF DATA HELD</h3>
                <p>
                  We keep several categories of personal data on our employees
                  in order to carry out effective and efficient processes. We
                  keep this data in a personnel file relating to each employee
                  and we also hold the data within our computer systems, for
                  example, our holiday booking system.
                </p>
                <p>Specifically, we hold the following types of data:</p>
                <ol>
                  <li>Personal details such as name, address, phone numbers</li>
                  <li>
                    Information gathered via the recruitment process such as
                    that entered into a CV or included in a CV cover letter,
                    references from former employers, details on your education
                    and employment history etc
                  </li>
                  <li>
                    Details relating to pay administration such as National
                    Insurance numbers, bank account details and tax codes
                  </li>
                  <li>Medical or health information</li>
                  <li>
                    Information relating to your employment with us, including:
                  </li>
                  <ul>
                    <li>Job title and job descriptions</li>
                    <li>Your salary</li>
                    <li>Your wider terms and conditions of employment</li>
                    <li>
                      Details of formal and informal proceedings involving you
                      such as letters of concern, disciplinary and grievance
                      proceedings, your annual leave records, appraisal and
                      performance information
                    </li>
                    <li>Internal and external training modules undertaken</li>
                  </ul>
                </ol>
                <p>
                  All of the above information is required for our processing
                  activities. More information on those processing activities
                  are included in our privacy notice for employees, which is
                  available from your manager.
                </p>

                <h3>EMPLOYEE RIGHTS</h3>
                <p>
                  You have the following rights in relation to the personal data
                  we hold on you:
                </p>
                <ol>
                  <li>
                    The right to be informed about the data we hold on you and
                    what we do with it;
                  </li>
                  <li>
                    The right of access to the data we hold on you. More
                    information on this can be found in the section headed
                    “Access to Data” below and in our separate policy on Subject
                    Access Requests”;
                  </li>
                  <li>
                    The right for any inaccuracies in the data we hold on you,
                    however they come to light, to be corrected. This is also
                    known as ‘rectification’;
                  </li>
                  <li>
                    The right to have data deleted in certain circumstances.
                    This is also known as ‘erasure’;
                  </li>
                  <li>The right to restrict the processing of the data;</li>
                  <li>
                    The right to transfer the data we hold on you to another
                    party. This is also known as ‘portability’;
                  </li>
                  <li>
                    The right to object to the inclusion of any information;
                  </li>
                  <li>
                    The right to regulate any automated decision-making and
                    profiling of personal data.
                  </li>
                </ol>
                <p>
                  More information can be found on each of these rights in our
                  separate policy on employee rights under GDPR.
                </p>
                <h3>RESPONSIBILITIES</h3>
                <p>
                  In order to protect the personal data of relevant individuals,
                  those within our business who must process data as part of
                  their role have been made aware of our policies on data
                  protection.
                </p>
                <p>
                  We have also appointed employees with responsibility for
                  reviewing and auditing our data protection systems.
                </p>

                <h3>LAWFUL BASES OF PROCESSING</h3>
                <p>
                  We acknowledge that processing may be only be carried out
                  where a lawful basis for that processing exists and we have
                  assigned a lawful basis against each processing activity.
                </p>
                <p>
                  Where no other lawful basis applies, we may seek to rely on
                  the employee’s consent in order to process data.
                </p>
                <p>
                  However, we recognise the high standard attached to its use.
                  We understand that consent must be freely given, specific,
                  informed and unambiguous. Where consent is to be sought, we
                  will do so on a specific and individual basis where
                  appropriate. Employees will be given clear instructions on the
                  desired processing activity, informed of the consequences of
                  their consent and of their clear right to withdraw consent at
                  any time.
                </p>

                <h3>ACCESS TO DATA</h3>

                <p>
                  As stated above, employees have a right to access the personal
                  data that we hold on them. To exercise this right, employees
                  should make a Subject Access Request. We will comply with the
                  request without delay, and within one month unless, in
                  accordance with legislation, we decide that an extension is
                  required. Those who make a request will be kept fully informed
                  of any decision to extend the time limit.
                </p>
                <p>
                  No charge will be made for complying with a request unless the
                  request is manifestly unfounded, excessive or repetitive, or
                  unless a request is made for duplicate copies to be provided
                  to parties other than the employee making the request. In
                  these circumstances, a reasonable charge will be applied.
                </p>
                <p>
                  Further information on making a subject access request is
                  contained in our Subject Access Request policy.
                </p>

                <h3>DATA DISCLOSURES</h3>
                <p>
                  The Company may be required to disclose certain
                  data/information to any person. The circumstances leading to
                  such disclosures include:
                </p>
                <ol>
                  <li>Any employee benefits operated by third parties;</li>
                  <li>
                    Disabled individuals - whether any reasonable adjustments
                    are required to assist them at work
                  </li>
                  <li>
                    Individuals health data - to comply with health and safety
                    or occupational health obligations towards the employee;
                  </li>
                  <li>For Statutory Sick Pay purposes;</li>
                  <li>
                    HR management and administration - to consider how an
                    individuals health affects his or her ability to do their
                    job;
                  </li>
                  <li>
                    The smooth operation of any employee insurance policies or
                    pension plans;
                  </li>
                  <li>
                    To assist law enforcement or a relevant authority to prevent
                    or detect crime or prosecute offenders or to assess or
                    collect any tax or duty.
                  </li>
                </ol>
                <p>
                  These kinds of disclosures will only be made when strictly
                  necessary for the purpose.
                </p>
                <h3>DATA SECURITY</h3>
                <p>
                  All our employees are aware that hard copy personal
                  information should be kept in a locked filing cabinet, drawer,
                  or safe.
                </p>
                <p>
                  Employees are aware of their roles and responsibilities when
                  their role involves the processing of data. All employees are
                  instructed to store files or written information of a
                  confidential nature in a secure manner so that are only
                  accessed by people who have a need and a right to access them
                  and to ensure that screen locks are implemented on all PCs,
                  laptops etc when unattended. No files or written information
                  of a confidential nature are to be left where they can be read
                  by unauthorised people.
                </p>
                <p>
                  Where data is computerised, it should be coded, encrypted or
                  password protected both on a local hard drive and on a network
                  drive that is regularly backed up. If a copy is kept on
                  removable storage media, that media must itself be kept in a
                  locked filing cabinet, drawer, or safe.
                </p>
                <p>
                  Employees must always use the passwords provided to access the
                  computer system and not abuse them by passing them on to
                  people who should not have them.
                </p>
                <p>
                  Personal data relating to employees should not be kept or
                  transported on laptops, USB sticks, or similar devices, unless
                  prior authorisation has been received. Where personal data is
                  recorded on any such device it should be protected by:
                </p>
                <ol>
                  <li>
                    Ensuring that data is recorded on such devices only where
                    absolutely necessary.
                  </li>
                  <li>
                    Using an encrypted system &mdash; a folder should be created
                    to store the files that need extra protection and all files
                    created or moved to this folder should be automatically
                    encrypted.
                  </li>
                  <li>
                    Ensuring that laptops or USB drives are not left where they
                    can be stolen.
                  </li>
                </ol>
                <p>
                  Failure to follow the Companys rules on data security may be
                  dealt with via the Companys disciplinary procedure.
                  Appropriate sanctions include dismissal with or without notice
                  dependent on the severity of the failure.
                </p>

                <h3>THIRD PARTY PROCESSING</h3>
                <p>
                  Where we engage third parties to process data on our behalf,
                  we will ensure, via a data processing agreement with the third
                  party, that the third party takes such measures in order to
                  maintain the Companys commitment to protecting data.
                </p>
                <h3>INTERNATIONAL DATA TRANSFERS</h3>
                <p>
                  The Company does not transfer personal data to any recipients
                  outside of the EEA.
                </p>
                <h3>REQUIREMENT TO NOTIFY BREACHES</h3>
                <p>
                  All data breaches will be recorded on our Data Breach
                  Register. Where legally required, we will report a breach to
                  the Information Commissioner within 72 hours of discovery. In
                  addition, where legally required, we will inform the
                  individual whose data was subject to breach.
                </p>
                <p>
                  More information on breach notification is available in our
                  Breach Notification policy.
                </p>
                <h3>TRAINING</h3>
                <p>
                  New employees must read and understand the policies on data
                  protection as part of their induction.
                </p>
                <p>
                  All employees receive training covering basic information
                  about confidentiality, data protection and the actions to take
                  upon identifying a potential data breach.
                </p>
                <p>
                  The nominated data controller/auditors/protection officers for
                  the Company are trained appropriately in their roles under the
                  GDPR.
                </p>
                <p>
                  All employees who need to use the computer system are trained
                  to protect individuals private data, to ensure data security,
                  and to understand the consequences to them as individuals and
                  the Company of any potential lapses and breaches of the
                  Companys policies and procedures.
                </p>
                <h3>RECORDS</h3>
                <p>
                  The Company keeps records of its processing activities
                  including the purpose for the processing and retention periods
                  in its HR Data Record. These records will be kept up to date
                  so that they reflect current processing activities.
                </p>
                <h3>DATA PROTECTION COMPLIANCE</h3>
                <p>Our Data Protection Officer is:</p>
                <p>Simon Gorringe ___________________(Name)</p>
                <p>simongorringe@w-hanson.co.uk_______(Contact details).</p>

                <h1 className="text-center " style={{ fontWeight: "550" }}>
                  COMMUNICATIONS POLICY
                </h1>

                <h3>INTRODUCTION</h3>
                <p>
                  IT and Communication plays an essential role in the conduct of
                  our business. The IT infrastructure including e-mail and
                  internet access have therefore significantly improved business
                  operations and efficiencies.
                </p>
                <p>
                  How you communicate with people not only reflects on you as an
                  individual but also on us as a business. As a result of this
                  the company values your ability to communicate with
                  colleagues, clients/customers and business contacts but we
                  must also ensure that such systems and access are managed
                  correctly, not abused in how they are used or what they are
                  used for.
                </p>
                <p>
                  This policy applies to all members of the Company who use our
                  or our clients communications facilities, whether
                  Directors/Consultants, full or part-time employees, contract
                  staff or temporary staff. The parameters and restrictions are
                  outlined below and you are required to read them carefully.
                </p>
                <h3>GENERAL PRINCIPLES</h3>
                <p>
                  You must use our and our clients information technology and
                  communications facilities sensibly, professionally, lawfully,
                  consistently with your duties and in accordance with this
                  policy and other Company rules and procedures.
                </p>
                <p>
                  At all times employees must behave with honesty and integrity
                  and respect the rights and privacy of others in relation to
                  electronic communication and information. The company reserves
                  the right to maintain all electronic communication and files.
                </p>
                <p>
                  Every employee will be given access to the Intranet and/or
                  Internet as appropriate to their job needs. For those who do
                  not have daily PC access occasional access will be arranged,
                  as necessary, by Management,
                </p>
                <p>
                  All PC/network access will be through passwords, and no
                  individual is permitted onto the system using another
                  employees password. Employees are not permitted to share their
                  password with anyone inside or outside the company.
                  Individuals will be allowed to set their own passwords, and
                  must change them as frequently as requested by the system
                  set-up requirements.
                </p>
                <p>
                  All information relating to our clients/customers and our
                  business operations is confidential. You must treat our
                  paper-based and electronic information with utmost care.
                </p>
                <p>
                  Many aspects of communication are protected by intellectual
                  property rights which can be infringed in a number of ways.
                  Downloading, copying, possessing and distributing material
                  from the internet may be an infringement of copyright or of
                  other intellectual property rights.
                </p>
                <p>
                  Particular care must be taken when using e-mail as a means of
                  communication because all expressions of fact, intention and
                  opinion in an e-mail may bind you and/or the Company and can
                  be produced in court in the same way as other kinds of written
                  statements.
                </p>
                <p>
                  If you are speaking with someone face to face, via the
                  telephone, in writing via whatever medium you are a
                  representative of the Company. Whilst in this role you should
                  not express any personal opinion that you know or suspect
                  might be contrary to the opinions of the Directors or Company
                  policy.
                </p>
                <p>
                  You must not use any of our or our clients media to do or say
                  anything which would be subject to disciplinary or legal
                  action in any other context such as sending any sexist,
                  racist, defamatory or other unlawful material. If you are in
                  doubt about a course of action, take advice from a member of
                  management.
                </p>

                <h3>USE OF ELECTRONIC MAIL</h3>
                <p>
                  1) <strong>Business use :</strong> Always use the "Bcc" box
                  when mailing to groups whenever the members of the group are
                  unaware of the identity of all the others (as in the case of
                  marketing mailing lists), or where you judge that the
                  membership of the group of one or more individuals should
                  perhaps not be disclosed to the others (as in the case of
                  members of a staff benefit scheme), because if you use the
                  "Cc" box each recipient is informed of the identity (and in
                  the case of external recipients, the address) of all the
                  others. Such a disclosure may breach any duty of confidence
                  owed to each recipient, breach the Company's obligations under
                  the General Data Protection Regulation and Data Protection Act
                  or may inadvertently disclose confidential business
                  information such as a marketing list. This applies to both
                  external and internal e-mail.
                </p>
                <p>
                  Expressly agree with the customer/client that the use of
                  e-mail is an acceptable form of communication bearing in mind
                  that if the material is confidential, privileged or
                  commercially sensitive then un-encrypted e-mail is not secure.
                </p>
                <p>
                  If you have sent an important document, always telephone to
                  confirm that the e-mail has been received and read.
                </p>
                <p>
                  In light of the security risks inherent in web-based e-mail
                  accounts, you must not e-mail business documents to your
                  personal web-based accounts. You may send documents to a
                  customer's/client's web-based account if you have the
                  customer's/client's express written permission to do so.
                  However, under no circumstances should you send sensitive or
                  highly confidential documents to a customer's/client's
                  personal web-based e-mail account (e.g. Yahoo, or Hotmail),
                  even if the customer/client asks you to do so.
                </p>
                <p>
                  2) <strong>Personal use :</strong>
                </p>
                <ol>
                  <li>
                    Although our e-mail facilities are provided for the purposes
                    of our business, we accept that you may occasionally want to
                    use them for your own personal purposes. This is permitted
                    on condition that all the procedures and rules set out in
                    this policy are complied with. Be aware, however, that if
                    you choose to make use of our facilities for personal
                    correspondence, the Company may need to monitor
                    communications for the reasons shown below.
                  </li>
                  <li>
                    Under no circumstances may the Company's facilities be used
                    in connection with the operation or management of any
                    business other than that of the Company or a customer/client
                    of the Company unless express permission has been obtained
                    from a member of management.
                  </li>
                  <li>
                    c)You must ensure that your personal e-mail use:
                    <ul>
                      <li>
                        Does not interfere with the performance of your duties;
                      </li>
                      <li>
                        Does not take priority over your work responsibilities;
                      </li>
                      <li>
                        Does not cause unwarranted expense or liability to be
                        incurred by the Company or our clients;
                      </li>
                      <li>lawful and complies with this policy.</li>
                      <li>
                        Does not have a negative impact on our business in any
                        way
                      </li>
                    </ul>
                  </li>
                  <li>
                    The Company will not tolerate the use of the E-mail system
                    for unofficial or inappropriate purposes, including:
                    <ul>
                      <li>
                        Any messages that could constitute bullying, harassment
                        or other detriment;
                      </li>
                      <li>On-line gambling;</li>
                      <li>Accessing or transmitting pornography;</li>
                      <li>
                        Transmitting copyright information and/or any software
                        available to the user; or
                      </li>
                      <li>
                        Posting confidential information about other employees,
                        the Company or its customers or suppliers.
                      </li>
                    </ul>
                  </li>
                </ol>
                <h3>USE OF INTERNET AND INTRANET</h3>
                <p>
                  We trust you to use the internet sensibly. Although internet
                  facilities are provided for the purposes of our business, we
                  accept that you may occasionally want to use them for your own
                  personal purposes. This is permitted on condition that all the
                  procedures and rules set out in this policy are complied with
                  and your use of the internet does not interfere in any way
                  with the performance of your duties.
                </p>
                <p>
                  2)Whenever you access a web site, you should always comply
                  with the terms and conditions governing its use. Care must be
                  taken in the use of information accessed through the Internet.
                  Most information is unregulated, and as such there is no
                  guarantee of accuracy.
                </p>
                <p>
                  3)The use of the Internet to access and/or distribute any kind
                  of offensive material, or material that is not work-related,
                  leaves an individual liable to disciplinary action which could
                  lead to dismissal.
                </p>
                <p>You must not:</p>
                <ol>
                  <li>
                    Use any images, text or material which are
                    copyright-protected, other than in accordance with the terms
                    of the license under which you were permitted to download
                    them;
                  </li>
                  <li>
                    Introduce packet-sniffing or password-detecting software;
                  </li>
                  <li>
                    Seek to gain access to restricted areas of the Company's
                    network;
                  </li>
                  <li>
                    Access or try to access data which you know or ought to know
                    is confidential;
                  </li>
                  <li>Introduce any form of computer virus; nor</li>
                  <li>Carry out any hacking activities.</li>
                </ol>
                <h3>VIRUS PROTECTION PROCEDURES</h3>
                <p>
                  In order to prevent the introduction of virus contamination
                  into the software system the following must be observed:-
                </p>
                <ol>
                  <li>
                    a)unauthorised software including public domain software,
                    magazine cover disks/CDs or Internet/World Wide Web
                    downloads must not be used; and
                  </li>
                  <li>
                    all software must be virus checked using standard testing
                    procedures before being used.
                  </li>
                </ol>
                <h3>USE OF COMPUTER EQUIPMENT</h3>
                <p>
                  In order to control the use of the Company's computer
                  equipment and reduce the risk of contamination the following
                  will apply:
                </p>
                <ol>
                  <li>
                    The introduction of new software must first of all be
                    checked and authorised by a member of management or a
                    client's nominated senior member of management before
                    general use will be permitted.
                  </li>
                  <li>
                    Only authorised staff should have access to the Company's
                    computer equipment.
                  </li>
                  <li>
                    Only authorised software may be used on any of the Company's
                    computer equipment.
                  </li>
                  <li>
                    Only software that is used for business applications may be
                    used.
                  </li>
                  <li>
                    No software may be brought onto or taken from the Company's
                    premises without prior authorisation.
                  </li>
                  <li>
                    Unauthorised access to the computer facility will result in
                    disciplinary action.
                  </li>
                  <li>
                    g)Unauthorised copying and/or removal of computer
                    equipment/software will result in disciplinary action, such
                    actions could lead to dismissal.
                  </li>
                </ol>
                <h3>SYSTEM SECURITY</h3>
                <p>
                  Security of our or our clients' IT systems is of paramount
                  importance. We owe a duty to all of our customers/clients to
                  ensure that all of our business transactions are kept
                  confidential. If at any time we need to rely in court on any
                  information which has been stored or processed using our IT
                  systems it is essential that we are able to demonstrate the
                  integrity of those systems. Every time you use the system you
                  take responsibility for the security implications of what you
                  are doing.
                </p>
                <p>
                  The Company's system or equipment must not be used in any way
                  which may cause damage, or overloading or which may affect its
                  performance or that of the internal or external network.
                </p>
                <p>
                  Keep all confidential information secure, use it only for the
                  purposes intended and do not disclose it to any unauthorised
                  third party.
                </p>
                <h3>WORKING REMOTELY</h3>
                <p>
                  This part of the policy and the procedures in it apply to your
                  use of our systems, to your use of our laptops, and also to
                  your use of your own computer equipment or other computer
                  equipment (e.g. client's equipment) whenever you are working
                  on Company business away from our premises (working remotely).
                </p>
                <p>When you are working remotely you must:</p>
                <ul>
                  <li>
                    Password protect any work which relates to our business so
                    that no other person can access your work;
                  </li>
                  <li>
                    Position yourself so that your work cannot be overlooked by
                    any other person;
                  </li>
                  <li>
                    Take reasonable precautions to safeguard the security of our
                    laptop computers and any computer equipment on which you do
                    Company business, and keep your passwords secret;
                  </li>
                  <li>
                    Inform the police and the Company as soon as possible if
                    either a Company laptop in your possession or any computer
                    equipment on which you do our work has been stolen; and
                  </li>
                  <li>
                    Ensure that any work which you do remotely is saved on the
                    Company system or is transferred to our system as soon as
                    reasonably practicable.
                  </li>
                </ul>
                <p>
                  PDAs or similar hand-held devices are easily stolen and not
                  very secure so you must password-protect access to any such
                  devices used by you on which is stored any personal data of
                  which the Company is a data controller or any information
                  relating our business, our clients or their business.
                </p>
                <h3>&bull; PERSONAL TELEPHONE CALLS/ MOBILE PHONES</h3>
                <p>
                  Telephones are essential for our business. Incoming/outgoing
                  personal telephone calls are allowed at the Company's head
                  office but should be kept to a minimum. We reserve the right
                  to recharge for excessive personal use. When visiting or
                  working on client premises you should always seek permission
                  before using our clients' telephone facilities.
                </p>
                <p>
                  Personal mobile phones should be switched off or on silent'
                  during working hours and only used during authorised breaks.
                </p>
                <h3>&bull; MONITORING OF COMMUNICATIONS BY THE COMPANY</h3>
                <p>
                  The Company is ultimately responsible for all business
                  communications but subject to that will, so far as possible
                  and appropriate, respect your privacy and autonomy. The
                  Company may monitor your business communications for reasons
                  which include:
                </p>
                <ol>
                  <li>Providing evidence of business transactions;</li>
                  <li>
                    Ensuring that our business procedures, policies and
                    contracts with staff are adhered to;
                  </li>
                  <li>Complying with any legal obligations;</li>
                  <li>
                    Monitoring standards of service, staff performance, and for
                    staff training;
                  </li>
                  <li>
                    Preventing or detecting unauthorised use of our
                    communications systems or criminal activities; and
                  </li>
                  <li>
                    Maintaining the effective operation of Company communication
                    systems.
                  </li>
                </ol>
                <p>
                  From time to time the Company may monitor telephone, e-mail
                  and internet traffic data (i.e. sender, receiver, subject;
                  non-business attachments to e-mail, numbers called and
                  duration of calls; domain names of web sites visited, duration
                  of visits, and non-business files downloaded from the
                  internet) at a network level (but covering both personal and
                  business communications). This includes monitoring of any
                  additional accounts you may be requested to set up for the
                  purposes of performing your work tasks, which are subject to
                  the same rules as your work email account. Information
                  acquired through such monitoring may be used as evidence in
                  disciplinary proceedings.
                </p>
                <p>
                  Sometimes it is necessary for us to access your business
                  communications during your absence, such as when you are away
                  because you are ill or while you are on holiday.
                </p>

                <h3>
                  <strong> DATA PROTECTION</strong>
                </h3>
                <p>
                  As an employee using our communications facilities, you will
                  inevitably be involved in processing personal data for the
                  Company as part of your job. Data protection is about the
                  privacy of individuals, and is governed by the General Data
                  Protection Regulation and current Data Protection Act.
                </p>
                <p>
                  Whenever and wherever you are processing personal data for the
                  Company you must keep this secret, confidential and secure,
                  and you must take particular care not to disclose such data to
                  any other person (whether inside or outside the Company)
                  unless authorised to do so. Do not use any such personal data
                  except as authorised by us for the purposes of your job. If in
                  doubt ask a member of management.
                </p>
                <p>
                  The Act gives every individual the right to see all the
                  information which any data controller holds about them. Bear
                  this in mind when recording personal opinions about someone,
                  whether in an e-mail or otherwise. It is another reason why
                  personal remarks and opinions made should be given
                  responsibly, must be relevant and appropriate as well as
                  accurate and justifiable.
                </p>
                <p>
                  For your information, the Act provides that it is a criminal
                  offence to obtain or disclose personal data without the
                  consent of the data controller. "Obtaining" here includes the
                  gathering of personal data by employees at work without the
                  authorisation of the employer. You may be committing this
                  offence if without authority of the Company: you exceed your
                  authority in collecting personal data; you access personal
                  data held by us; or you pass them on to someone else (whether
                  inside or outside the Company).
                </p>
                <h3>USE OF SOCIAL NETWORKING SITES</h3>
                <p>
                  Any work related issue or material that could identify an
                  individual who is a customer/client or work colleague, which
                  could adversely affect the company a customer/client or our
                  relationship with any customer/client must not be placed on a
                  social networking site. This means that work related matters
                  must not be placed on any such site at any time either during
                  or outside of working hours and includes access via any
                  computer equipment, mobile phone or PDA.
                </p>
                <h3>CONFIDENTIALITY</h3>
                <p>
                  Employees are not permitted to register with sites or
                  electronic services in the company's name without the prior
                  permission of their manager. They are not permitted to reveal
                  internal company information to any sites, be it confidential
                  or otherwise, or comment on company matters, even if this is
                  during after-hours or personal use. The company
                  confidentiality policy applies to all electronic communication
                  and data.
                </p>
                <h3>COMPLIANCE WITH THIS POLICY</h3>
                <p>
                  Failure to comply with this policy may result in disciplinary
                  action being taken against you. If there is anything in this
                  policy that you do not understand, please discuss it with a
                  member of management.
                </p>
                <p>
                  Please note that the procedures and policies outlined in this
                  policy, and in any related policy, may be reviewed or changed
                  at any time.
                </p>

                <h1 className="text-center " style={{ fontWeight: "550" }}>
                  POLICY ON YOUR RIGHTS IN RELATION TO YOUR DATA
                </h1>

                <h3>AIM</h3>
                <p>
                  This policy outlines the rights that data subjects have, under
                  the General Data Protection Regulation (GDPR), in relation to
                  the data about them that we hold. Data subjects, for the
                  purposes of this policy, includes employees (current,
                  prospective and former), workers and contractors.
                </p>
                <h3>THE RIGHT TO BE INFORMED</h3>
                <p>
                  In order to keep you informed about how we use your data, we
                  have a privacy notice for employees. You can obtain a copy of
                  the privacy notice from your manager.
                </p>
                <p>
                  The Company also has a separate privacy notice applicable to
                  job applicants, available from your manager.
                </p>
                <p>
                  You will not be charged for receiving our privacy notices.
                </p>
                <p>Our privacy notices set out:</p>
                <ol>
                  <li>
                    The types of data we hold and the reason for processing the
                    data;
                  </li>
                  <li>Our legitimate interest for processing it;</li>
                  <li>
                    Details of who your data is disclosed to and why, including
                    transfers to other countries. Where data is transferred to
                    other counties, the safeguards used to keep your data secure
                    are explained
                  </li>
                  <li>
                    How long we keep your data for, or how we determine how long
                    to keep your data for
                  </li>
                  <li>Where your data comes from</li>
                  <li>Your rights as a data subject</li>
                  <li>
                    Your absolute right to withdraw consent for processing data
                    where consent has been provided and no other lawful reason
                    for processing your data applies
                  </li>
                  <li>
                    Your right to make a complaint to the Information
                    Commissioner if you think your rights have been breached
                  </li>
                  <li>
                    Whether we use automated decision making and if so, how the
                    decisions are made, what this means for you and what could
                    happen as a result of the process
                  </li>
                  <li>
                    The name and contact details of our data protection officer
                  </li>
                </ol>
                <h3>THE RIGHT OF ACCESS</h3>
                <p>
                  You have the right to access your personal data which is held
                  by us. You can find out more about how to request access to
                  your data by reading our Subject Access Request policy.
                </p>
                <h3>THE RIGHT TO CORRECTION</h3>
                <p>
                  If you discover that the data we hold about you is incorrect
                  or incomplete, you have the right to have the data corrected.
                  If you wish to have your data corrected, you should complete
                  the Data Correction Form.
                </p>
                <p>
                  Usually, we will comply with a request to rectify data within
                  one month unless the request is particularly complex in which
                  case we may write to you to inform you we require an extension
                  to the normal timescale. The maximum extension period is two
                  months.
                </p>
                <p>
                  You will be informed if we decide not to take any action as a
                  result of the request. In these circumstances, you are able to
                  complain to the Information Commissioner and have access to a
                  judicial remedy.
                </p>
                <p>
                  Third parties to whom the data was disclosed will be informed
                  of the rectification.
                </p>
                <h3>THE RIGHT OF ERASURE</h3>
                <p>
                  In certain circumstances, we are required to delete the data
                  we hold on you. Those circumstances are:
                </p>
                <ol>
                  <li>
                    Where it is no longer necessary for us to keep the data;
                  </li>
                  <li>
                    Where we relied on your consent to process the data and you
                    subsequently withdraw that consent. Where this happens, we
                    will consider whether another legal basis applies to our
                    continued use of your data;
                  </li>
                  <li>
                    Where you object to the processing (see below) and the
                    Company has no overriding legitimate interest to continue
                    the processing;
                  </li>
                  <li>Where we have unlawfully processed your data;</li>
                  <li>Where we are required by law to erase the data.</li>
                </ol>
                <p>
                  If you wish to make a request for data deletion, you should
                  complete the Data Erasure form.
                </p>
                <p>
                  We will consider each request individually, however, you must
                  be aware that processing may continue under one of the
                  permissible reasons. Where this happens, you will be informed
                  of the continued use of your data and the reason for this.
                </p>
                <p>
                  Third parties to whom the data was disclosed will be informed
                  of the erasure where possible unless to do so will cause a
                  disproportionate effect on us.
                </p>
                <h3>THE RIGHT OF RESTRICTION</h3>
                <p>
                  You have the right to restrict the processing of your data in
                  certain circumstances.
                </p>
                <p>
                  We will be required to restrict the processing of your
                  personal data in the following circumstances:
                </p>
                <ol>
                  <li>
                    where you tell us that the data we hold on you is not
                    accurate. Where this is the case, we will stop processing
                    the data until we have taken steps to ensure that the data
                    is accurate;
                  </li>
                  <li>
                    where the data is processed for the performance of a public
                    interest task or because of our legitimate interests and you
                    have objected to the processing of data. In these
                    circumstances, the processing may be restricted whilst we
                    consider whether our legitimate interests mean it is
                    appropriate to continue to process it;
                  </li>
                  <li>when the data has been processed unlawfully;</li>
                  <li>
                    where we no longer need to process the data but you need the
                    data in relation to a legal claim.
                  </li>
                </ol>
                <p>
                  If you wish to make a request for data restriction, you should
                  complete the Data Restriction form.
                </p>
                <p>
                  Where data processing is restricted, we will continue to hold
                  the data but will not process it unless you consent to the
                  processing or processing is required in relation to a legal
                  claim.
                </p>
                <p>
                  Where the data to be restricted has been shared with third
                  parties, we will inform those third parties of the restriction
                  where possible unless to do so will cause a disproportionate
                  effect on us.
                </p>
                <p>You will be informed before any restriction is lifted.</p>
                <h3> THE RIGHT TO DATA PORTABILITY</h3>
                <p>
                  You have the right to obtain the data that we process on you
                  and transfer it to another party. Where our technology
                  permits, we will transfer the data directly to the other
                  party.
                </p>
                <p>Data which may be transferred is data which:</p>
                <ol>
                  <li>You have provided to us; and</li>
                  <li>
                    Processed because you have provided your consent or because
                    it is needed to perform the employment contract between us
                  </li>
                  <li>Processed by automated means.</li>
                </ol>
                <p>
                  If you wish to exercise this right, please speak to your
                  manager.
                </p>
                <p>
                  We will respond to a portability request without undue delay,
                  and within one month at the latest unless the request is
                  complex or we receive a number of requests in which case we
                  may write to you to inform you that we require an extension
                  and reasons for this. The maximum extension period is two
                  months.
                </p>
                <p>
                  We will not charge you for access to your data for this
                  purpose.
                </p>
                <p>
                  You will be informed if we decide not to take any action as a
                  result of the request, for example, because the data you wish
                  to transfer does not meet the above criteria. In these
                  circumstances, you are able to complain to the Information
                  Commissioner and have access to a judicial remedy.
                </p>
                <p>
                  The right to data portability relates only to data defined as
                  above. You should be aware that this differs from the data
                  which is accessible via a Subject Access Request.
                </p>
                <h3>THE RIGHT TO OBJECT</h3>
                <p>
                  You have a right to require us to stop processing your data;
                  this is known as data objection.
                </p>
                <p>You may object to processing where it is carried out:</p>
                <ol>
                  <li>in relation to the Companys legitimate interests</li>
                  <li>for the performance of a task in the public interest</li>
                  <li>in the exercise of official authority; or</li>
                  <li>d)for profiling purposes.</li>
                </ol>
                <p>
                  If you wish to object, you should do so by completing the Data
                  Objection Form.
                </p>
                <p>
                  In some circumstances we will continue to process the data you
                  have objected to. This may occur when:
                </p>
                <ol>
                  <li>
                    We can demonstrate compelling legitimate reasons for the
                    processing which are believed to be more important than your
                    rights; or
                  </li>
                  <li>
                    The processing is required in relation to legal claims made
                    by, or against, us.
                  </li>
                </ol>
                <p>
                  If the response to your request is that we will take no
                  action, you will be informed of the reasons.
                </p>
                <h3> RIGHT NOT TO HAVE AUTOMATED DECISIONS MADE ABOUT YOU</h3>
                <p>
                  You have the right not to have decisions made about you solely
                  on the basis of automated decision making processes where
                  there is no human intervention, where such decisions will have
                  a significant effect on you.
                </p>
                <p>
                  However, the Company does not make any decisions based on such
                  processes.
                </p>

                <h1 className="text-center " style={{ fontWeight: "550" }}>
                  PRIVACY NOTICE FOR EMPLOYEES/WORKERS
                </h1>

                <p>
                  In accordance with the General Data Protection Regulation
                  (GDPR), we have implemented this privacy notice to inform you,
                  our employees, of the types of data we process about you. We
                  also include within this notice the reasons for processing
                  your data, the lawful basis that permits us to process it, how
                  long we keep your data for and your rights regarding your
                  data.
                </p>
                <p>
                  This notice applies to current and former employees and
                  workers.
                </p>
                <h3>DATA PROTECTION PRINCIPLES</h3>
                <p>
                  Under GDPR, all personal data obtained and held by us must be
                  processed according to a set of core principles. In accordance
                  with these principles, we will ensure that:
                </p>
                <ol>
                  <li>processing is fair, lawful and transparent</li>
                  <li>
                    data is collected for specific, explicit, and legitimate
                    purposes
                  </li>
                  <li>
                    data collected is adequate, relevant and limited to what is
                    necessary for the purposes of processing
                  </li>
                  <li>
                    data is kept accurate and up to date. Data which is found to
                    be inaccurate will be rectified or erased without delay
                  </li>
                  <li>
                    data is not kept for longer than is necessary for its given
                    purpose
                  </li>
                  <li>
                    data is processed in a manner that ensures appropriate
                    security of personal data including protection against
                    unauthorised or unlawful processing, accidental loss,
                    destruction or damage by using appropriate technical or
                    organisation measures
                  </li>
                  <li>
                    g)we comply with the relevant GDPR procedures for
                    international transferring of personal data
                  </li>
                </ol>
                <h3>TYPES OF DATA HELD</h3>
                <p>
                  We keep several categories of personal data on our employees
                  in order to carry out effective and efficient processes. We
                  keep this data in a personnel file relating to each employee
                  and we also hold the data within our computer systems, for
                  example, our holiday booking system.
                </p>
                <p>
                  Specifically, we hold the following types of data, as
                  appropriate to your status:
                </p>
                <ol>
                  <li>personal details such as name, address, phone numbers</li>
                  <li>name and contact details of your next of kin</li>
                  <li>your photograph</li>
                  <li>
                    your gender, marital status, information of any disability
                    you have or other medical information
                  </li>
                  <li>right to work documentation</li>
                  <li>
                    information on your race and religion for equality
                    monitoring purposes
                  </li>
                  <li>
                    information gathered via the recruitment process such as
                    that entered into a CV or included in a CV cover letter
                  </li>
                  <li>references from former employers</li>
                  <li>details on your education and employment history etc</li>
                  <li>National Insurance numbers</li>
                  <li>bank account details</li>
                  <li>tax codes</li>
                  <li>driving licence</li>
                  <li>criminal convictions</li>
                  <li>
                    information relating to your employment with us, including:
                    <ul>
                      <li>job title and job descriptions</li>
                      <li>your salary</li>
                      <li>your wider terms and conditions of employment</li>
                      <li>
                        iv)details of formal and informal proceedings involving
                        you such as letters of concern, disciplinary and
                        grievance proceedings, your annual leave records,
                        appraisal and performance information
                      </li>
                      <li>internal and external training modules undertaken</li>
                      <li>
                        vi)information on time off from work including sickness
                        absence, family related leave etc
                      </li>
                    </ul>
                  </li>
                  <li>CCTV footage</li>
                  <li>building access card records</li>
                  <li>
                    IT equipment use including telephones and internet access.
                  </li>
                </ol>
                <h3>COLLECTING YOUR DATA</h3>
                <p>
                  You provide several pieces of data to us directly during the
                  recruitment period and subsequently upon the start of your
                  employment.
                </p>
                <p>
                  In some cases, we will collect data about you from third
                  parties, such as employment agencies, former employers when
                  gathering references or credit reference agencies.
                </p>
                <p>
                  Personal data is kept in files or within the Company's HR and
                  IT systems.
                </p>
                <h3>LAWFUL BASIS FOR PROCESSING</h3>
                <p>
                  The law on data protection allows us to process your data for
                  certain reasons only. In the main, we process your data in
                  order to comply with a legal requirement or in order to
                  effectively manage the employment contract we have with you,
                  including ensuring you are paid correctly.
                </p>
                <p>
                  The information below categorises the types of data
                  processing, appropriate to your status, we undertake and the
                  lawful basis we rely on.
                </p>
                <div className="">
                  <table className="table table-bordered ">
                    <thead>
                      <th className="" style={{ backgroundColor: "#f4f4f4" }}>
                        Activity requiring your data
                      </th>
                      <th style={{ backgroundColor: "#f4f4f4" }}>
                        Lawful basis
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Carry out the employment contract that we have entered
                          into with you e.g. using your name, contact details,
                          education history, information on any disciplinary,
                          grievance procedures involving you
                        </td>
                        <td>Performance of the contract</td>
                      </tr>
                      <tr>
                        <td>Ensuring you are paid</td>
                        <td>Performance of the contract</td>
                      </tr>
                      <tr>
                        <td>Ensuring tax and National Insurance is paid</td>
                        <td>Legal obligationF</td>
                      </tr>
                      <tr>
                        <td>
                          Carrying out checks in relation to your right to work
                          in the UK
                        </td>
                        <td>Legal obligation</td>
                      </tr>
                      <tr>
                        <td>
                          Making reasonable adjustments for disabled employees
                        </td>
                        <td>Legal obligation</td>
                      </tr>
                      <tr>
                        <td>
                          Making recruitment decisions in relation to both
                          initial and subsequent employment e.g. promotion
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Making decisions about salary and other benefits
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Ensuring efficient administration of contractual
                          benefits to you
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Effectively monitoring both your conduct, including
                          timekeeping and attendance, and your performance and
                          to undertake procedures where necessary
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Maintaining comprehensive up to date personnel records
                          about you to ensure, amongst other things, effective
                          correspondence can be achieved and appropriate contact
                          points in the event of an emergency are maintained
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>Implementing grievance procedures</td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>Assessing training needs</td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Implementing an effective sickness absence management
                          system including monitoring the amount of leave and
                          subsequent actions to be taken including the making of
                          reasonable adjustments
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Gaining expert medical opinion when making decisions
                          about your fitness for work
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Managing statutory leave and pay systems such as
                          maternity leave and pay etc
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>Business planning and restructuring exercises</td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>Dealing with legal claims made against us</td>
                        <td>Our legitimate interest</td>
                      </tr>
                      <tr>
                        <td>Preventing fraud</td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Ensuring our administrative and IT systems are secure
                          and robust against unauthorised access
                        </td>
                        <td>Our legitimate interests</td>
                      </tr>
                      <tr>
                        <td>
                          Providing employment references to prospective
                          employers, when our name has been put forward by the
                          employee/ex-employee, to assist with their effective
                          recruitment decisions
                        </td>
                        <td>Legitimate interest of the prospective employer</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>SPECIAL CATEGORIES OF DATA</h3>
                <p>Special categories of data are data relating to your:</p>
                <ol>
                  <li>health</li>
                  <li>sex life</li>
                  <li>sexual orientation</li>
                  <li>race</li>
                  <li>ethnic origin</li>
                  <li>political opinion</li>
                  <li>religion</li>
                  <li>trade union membership</li>
                  <li>genetic and biometric data.</li>
                </ol>
                <p>
                  We carry out processing activities using special category
                  data:
                </p>
                <ol>
                  <li>for the purposes of equal opportunities monitoring</li>
                  <li>in our sickness absence management procedures</li>
                  <li>to determine reasonable adjustments</li>
                </ol>
                <p>
                  Most commonly, we will process special categories of data when
                  the following applies:
                </p>
                <ol>
                  <li>you have given explicit consent to the processing</li>
                  <li>
                    we must process the data in order to carry out our legal
                    obligations
                  </li>
                  <li>
                    we must process data for reasons of substantial public
                    interest
                  </li>
                  <li>you have already made the data public.</li>
                </ol>
                <h3>
                  FAILURE TO PROVIDE DATA
                </h3>
                <p>
                  Your failure to provide us with data may mean that we are
                  unable to fulfil our requirements for entering into a contract
                  of employment with you. This could include being unable to
                  offer you employment, or administer contractual benefits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default PrivacyPolicy;
