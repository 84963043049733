import { Rate, notification } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader'
import config from '../../config'

const ProductReview = ({ product_id }) => {

  const [comment, setComment] = useState('')
  const [rating, setRating] = useState('')
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate();

  const onAddReview = async (e) => {
    e.preventDefault();
    if (!localStorage.getItem("auth_id") && !localStorage.getItem("auth_name")) {
      navigate("/users/login");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("product_id", product_id);
      formData.append("user_id", localStorage.getItem("auth_id"));
      formData.append("comment", comment);
      formData.append("rating", rating);
      try {
        const response = await axios({
          method: "post",
          url: `${config.apiUrl}/add_review`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          notification.open({
            message: (
              <div className="d-flex">
                <div className="pr-20">
                  <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                </div>
                <div>Thank you for visiting Whanson Group</div>
              </div>
            ),
            description: "Our Team Will Contact You Soon",
          });
          setLoading(false);
          setComment("");
          setRating("");

        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }


  return (
    <div>
      {isLoading && <Loader />}
      <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 ">
        <form className="ps-form--review" onSubmit={onAddReview}>
          <h4>Submit Your Review</h4>
          <div className="form-group form-group__rating">
            <label>Your rating of this product</label>
            <Rate defaultValue={0}
              value={rating}
              required
              onChange={(e) => setRating(e)} />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Write your review here*" required></textarea>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Your Name*"
                  required
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  placeholder="Your Email *"
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-group submit">
            <button className="ps-btn bg-blue color-white">Submit Review</button>
          </div>
        </form>
      </div>{/*  */}
    </div>
  )
}

export default ProductReview