import React from 'react';
import { Link, useParams } from 'react-router-dom';


const MenuDropdown = ({ source }) => {

    const params = useParams();

    function slugify(str) {
        return str
            .replace(/&/g, '')
            .replace(' -', '')
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/\//g, '')
    }

    return (
        <li className={"menu-item-has-children dropdown " + source.text}>
            {
                <Link to={source.url == `/${source.url}` ? '' : `${source.url}`}
                className={source.url == "/MDF-Skirting-Archtrave" ? 'mdf-skirting' : "" } >
                    <a className={slugify(source.text) == params.slug ? 'active-head' : ""} >{source.text}</a>
                    {source.url == "/MDF-Skirting-Archtrave" ? (
                        <ul className='sub-menu-menu'>
                            <li>
                                <Link to='/Ogee' >Ogee</Link>
                            </li>
                            <li>
                                <Link to="/6mm-Pencil-Round">6mm Pencil Round</Link>
                            </li>
                            <li>
                                <Link to="/Torus">Torus</Link>
                            </li>
                            <li>
                                <Link to="/CPPLC1">CPPLC1</Link>
                            </li>
                            <li>
                                <Link to="/PSE-with-15x4-Groove-17mm-Flat">PSE with 15x4 Groove 17mm Flat</Link>
                            </li>
                            <li>
                                <Link to="/BK247">BK247</Link>
                            </li>
                            <li>
                                <Link to="/Square-Edge">Square Edge</Link>
                            </li>
                            <li>
                                <Link to="/3mm-Pencil-Round">3mm Pencil Round</Link>
                            </li>
                            <li>
                                <Link to="/Chamfered-&-Rounded">Chamfered & Rounded</Link>
                            </li>
                            <li>
                                <Link to="/SP600">SP600</Link>
                            </li>
                            <li>
                                <Link to="/2mm-Bevel">2mm Bevel</Link>
                            </li>
                            <li>
                                <Link to="/Twice-Grooved-10x4-groove-20mm-from-Top">Twice Grooved (10x4 groove) 20mm from Top</Link>
                            </li>
                        </ul>
                    ) : null}
                </Link>
            }
            {source.subMenu && (
                <ul className={source.subClass}>
                    {source.subMenu.map((subMenuItem, index) => (
                        <MenuDropdown source={subMenuItem} key={index}  />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default MenuDropdown;
