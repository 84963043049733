import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/static/img/w-hanson-logo-white.png";
import axios from "axios";
import config from "../../../../config";
import Loader from "../../../../pages/Loader";
import { notification } from "antd";

const FooterWidgets = () => {
    const [email, setEmail] = useState("");
    const [isLoading, setLoading] = useState(false);
    const onGetinTouch = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("email", email);
        try {
            const response = await axios({
                method: "post",
                url: `${config.apiUrl}/get_in_touch`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                notification.open({
                    message: (
                        <div className="d-flex">
                            <div className="pr-20">
                                <i
                                    className="fa fa-check"
                                    style={{ color: "#4bb543" }}
                                ></i>
                            </div>
                            <div>Thank you for visiting Whanson Group</div>
                        </div>
                    ),
                    description: "Our Team Will Contact You Soon",
                });
                setLoading(false);
                setEmail("");
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    return (
        <div className="ps-footer__widgets justify-space-between">
            {isLoading && <Loader />}
            <aside className="widget widget_footer widget_contact-us ">
                <div className="widget_content">
                    <div>
                        <img src={Logo} className="mb-25" height="105" />
                    </div>
                    <div className="mb-5">
                        <div className="d-flex gap-2">
                            <a
                                className="social-icon"
                                href="https://www.facebook.com/whansonharrow/"
                                target="_blank"
                            >
                                <i className="fa fa-facebook facebook"></i>
                            </a>
                            <a
                                className="social-icon"
                                href="https://www.instagram.com/whansonharrow/"
                                target="_blank"
                            >
                                <i className="fa fa-instagram instagram"></i>
                            </a>
                        </div>
                    </div>
                    <form
                        className="ps-form--newletter"
                        action="#"
                        method="get"
                        onSubmit={onGetinTouch}
                    >
                        <div className="form-group--nest">
                            <input
                                className="form-control"
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button className="ps-btn bg-black color-white">
                                Subscribe
                            </button>
                        </div>
                    </form>
                </div>
            </aside>
            <aside className="widget widget_footer">
                <h4 className="widget-title">Contact Info </h4>
                <ul className="ps-list--link">
                    <li>
                        <p className="color-white">
                            <strong>Address :</strong> 92-108 Greenford Road,
                            Harrow, <br />
                            Middlesex, HA1 3QL
                        </p>
                    </li>
                    <li>
                        <a
                            className="color-white no-hover"
                            href="callto:020 8864 0822"
                        >
                            <strong>Phone :</strong> 020 8864 0822
                        </a>
                    </li>
                    <li>
                        <a
                            className="color-white no-hover"
                            href="mailto:websales@w-hanson.co.uk"
                        >
                            <strong>Email :</strong> websales@w-hanson.co.uk
                        </a>
                    </li>
                    <li>
                        <p className="color-white">
                            <strong>Our openings times are :</strong> <br />
                            Monday – Friday , 7.00am – 5.00pm & <br />
                            Saturday, Sunday & Bank Holidays Closed
                        </p>
                    </li>
                </ul>
            </aside>
            <aside className="widget widget_footer">
                <h4 className="widget-title">Quick Links </h4>
                <ul className="ps-list--link">
                    <li>
                        <Link
                            to="/bespoke-machine-service"
                            className="color-white no-hover"
                        >
                            Bespoke Machine Services
                        </Link>
                    </li>
                    <li>
                        <Link to="/about-us" className="color-white no-hover">
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact-us" className="color-white no-hover">
                            Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/slavery-human-trafficking"
                            className="color-white no-hover"
                        >
                            Slavery & Human Trafficking
                        </Link>
                    </li>
                    <li>
                        <Link to="/career" className="color-white no-hover">
                            Career
                        </Link>
                    </li>
                    <li>
                        <Link to="/faq" className="color-white no-hover">
                            FAQs
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/product-news"
                            className="color-white no-hover"
                        >
                            Product News
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/stock-clearance"
                            className="color-white no-hover"
                        >
                            Stock Clearance
                        </Link>
                    </li>
                    <li>
                        <Link to="/services" className="color-white no-hover">
                            Services
                        </Link>
                    </li>
                </ul>
            </aside>
            <aside className="widget widget_footer">
                <h4 className="widget-title">My Account</h4>
                <ul className="ps-list--link">
                    <li>
                        <Link
                            to="/users/login"
                            className="color-white no-hover"
                        >
                            Login
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile" className="color-white no-hover">
                            Order History
                        </Link>
                    </li>

                    {/* <li>
                    <Link to="/" className='color-white no-hover'>
                        Track Order
                    </Link>
                </li> */}
                </ul>
            </aside>
        </div>
    );
};
export default FooterWidgets;
