import React from "react";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import Image1 from "../../assets/static/img/Spindle Block.jpg";
import Image2 from "../../assets/static/img/Loaded Truck_1.JPG";
import Image3 from "../../assets/static/img/Striebig.jpg";
import Image4 from "../../assets/static/img/Joinery.JPG";
import Image5 from "../../assets/static/img/Primer.JPG";
import Image6 from "../../assets/static/img/Warehouse (1).JPG";
import { Helmet } from "react-helmet";
import FORS1 from "../../assets/static/img/FORS Bronze Harrow.pdf";
import FORS2 from "../../assets/static/img/FORS Bronze IB.pdf";
import FORS3 from "../../assets/static/img/FORS Silver Harrow.pdf";
import FORS4 from "../../assets/static/img/FORS Silver IB.pdf";
import swal from "sweetalert";
import config from "../../config";
const Services = () => {
  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Services",
    },
  ];

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;



  return (
    <div>
      <Helmet>
        <title>Professional Services Offered by W-Hanson UK</title>
        <meta
          name="description"
          Content="Discover exceptional services with W-Hanson UK, designed to meet your specific requirements. Visit our site for more information."
        />
      </Helmet>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--single">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-section--custom ">
            <div className="container">
              <div className="ps-section__header">
                <h1
                  className="text-center"
                  style={{
                    fontWeight: "550",
                    fontSize: "35px",
                    padding: "20px 0px 40px",
                  }}
                >
                  Our Services
                </h1>
              </div>
              <div className="ps-section__content policy-sections mt-20">
                <div className="row mb-4">
                  <div className="col-4 bd-join">
                    <div>
                      <img src={Image1} alt="No Image" />
                    </div>
                  </div>
                  <div className="col-8 bd-join   ">
                    <div className="bd-content-service">
                      <h1 className="mb-3">Mill</h1>
                      <p>
                        <i className="icon-arrow-right pr-10"></i>
                        At W.Hanson we are well known for our ability &
                        expertise to produce exact matches for skirtings and
                        architraves to match up with your period home. We have
                        over 50 years of experience & the technology to produce
                        an almost perfect match.
                      </p>
                      <p>
                        <i className="icon-arrow-right pr-10"></i>
                        We have a large working mill with a 2 spindle moulders,
                        2 thicknesser planers, an overhand planer, resaw, cross
                        cut & a 4 cutter moulder.
                      </p>
                      <p>
                        <i className="icon-arrow-right pr-10"></i>
                        These machines coupled with well over 100 years of
                        combined experience amongst our trained machinists,
                        makes us the ideal solution to any of your machining
                        requirement.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb-5 pb-4">
                  <div className="col-8 bd-join">
                    <div className="bd-content-service">
                      <h1 className="mb-3">Delivery</h1>
                      <p>
                        <i className="icon-arrow-right pr-10"></i>
                        We offer free local delivery for orders in excess of
                        £50, & can deliver out stock items on a next day basis,
                        on occasions even on the same day. We also deliver on a
                        daily basis out as far as Essex, Southampton,
                        Northampton, Guildford, and even Maidstone. We have HGV
                        lorries with cranes, 7.5T flatbeds, and transit van
                        tippers, so we can service all your requirements,
                        whether it be a full load of heavy side or a delivery
                        down a small service road. Please be aware that all our
                        deliveries are ‘curb side drop’ due to insurance
                        requirement.
                      </p>
                      <p>
                        We are now proud to be a bronze member of the Fleet
                        Operator Recognition Scheme -FORS. Please click on the
                        link below to see our FORS Certiicate.
                      </p>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3 text-center">
                          <a
                            href={FORS1}
                            download
                            className="btn-blue btn-block "
                          >
                            FORS Bronze Harrow{" "}
                            <i className="fa fa-download ml-2"></i>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3 text-center">
                          <a
                            href={FORS2}
                            download
                            className="btn-blue btn-block "
                          >
                            FORS Bronze IB{" "}
                            <i className="fa fa-download ml-2"></i>
                          </a>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3 text-center">
                          <a
                            href={FORS3}
                            download
                            className="btn-blue btn-block "
                          >
                            FORS Silver Harrow{" "}
                            <i className="fa fa-download ml-2"></i>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3 text-center">
                          <a
                            href={FORS4}
                            download
                            className="btn-blue btn-block"
                          >
                            FORS Silver IB{" "}
                            <i className="fa fa-download ml-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 bd-join">
                    <div>
                      <img src={Image2} alt="No Image" />
                    </div>
                  </div>
                </div>
                <div className="row mb-5 pb-4">
                  <div className="col-4 bd-join">
                    <div>
                      <img src={Image3} alt="No Image" />
                    </div>
                  </div>
                  <div className="col-8 bd-join">
                    <div className="bd-content-service">
                      <h1 className="mb-3">Panel Saw</h1>
                      <p>
                        <i className="icon-arrow-right pr-10"></i>
                        With our 2 panel saws – a brand new Streibig & a back up
                        Holz-er, we can get those large orders out without
                        disrupting service to our smaller customers. And with
                        our massive stock of panels, you can be sure that you
                        won’t need to go anywhere else.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb-5 pb-4">
                  <div className="col-8 bd-join">
                    <div className="bd-content-service">
                      <h1 className="mb-3">Joinery Shop</h1>
                      <p>
                        <i className="icon-arrow-right pr-10"></i>
                        W.Hanson has built its name on service & quality, which
                        is why we see our joinery shop as a hugely important and
                        intergral part of our available services. Our current
                        joiner has a long family history of working with timber,
                        and it is clear to see from his working, that he has a
                        huge pride in everything he does. While a lot of his
                        time is taken making door frames, and lipping doors, he
                        is clearly happiest when he has the opportunity to
                        produce chests of drawers, wardrobes, beds, louvre
                        shutters etc that give him the opportunity to get his
                        teeth into something a little different.
                      </p>
                    </div>
                  </div>
                  <div className="col-4 bd-join">
                    <div>
                      <img src={Image4} alt="No Image" />
                    </div>
                  </div>
                </div>
                <div className="row mb-5 pb-4">
                  <div className="col-4 bd-join">
                    <div>
                      <img src={Image5} alt="No Image" />
                    </div>
                  </div>
                  <div className="col-8 bd-join">
                    <div className="bd-content-service">
                      <h1 className="mb-3">Mobile Priming Unit</h1>
                      <p>
                        <i className="icon-arrow-right pr-10"></i>
                        We have recently added a brush coater to our
                        ever-growing list of services that we offer our
                        customers. With our client base demanding more
                        pre-primed timber, we decided that it was time for us to
                        invest in a new machine that could give us more
                        flexibility than relying on others. In it's first 3
                        months of use, this machine has seen in excess of 15000
                        running meters of softwood lining & door stop materials
                        run through it. Should customers require, we can also
                        prime skirtings, architraves, and any other mouldings
                        you may require.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb-5 pb-4">
                  <div className="col-8 bd-join">
                    <div className="bd-content-service">
                      <h1 className="mb-3">Our Commitments to You</h1>
                      <p className="mb-0">
                        <i className="icon-arrow-right pr-10"></i>
                        Extensive stocks backed up by a 10,000ft2 off premisis
                        warehouse.
                      </p>
                      <p className="mb-0">
                        <i className="icon-arrow-right pr-10"></i>Competitive
                        pricing.
                      </p>
                      <p className="mb-0">
                        <i className="icon-arrow-right pr-10"></i>Prompt &
                        reliable delivery service.
                      </p>
                      <p className="mb-0">
                        <i className="icon-arrow-right pr-10"></i>Technical
                        advice from our experienced staff.
                      </p>
                      <p className="mb-0">
                        <i className="icon-arrow-right pr-10"></i>Trade accounts
                        available.
                      </p>
                      <p className="mb-0">
                        <i className="icon-arrow-right pr-10"></i>No job too big
                        or too small.
                      </p>
                      <p className="mb-0">
                        <i className="icon-arrow-right pr-10"></i>We can match
                        almost any pattern in timber.
                      </p>
                    </div>
                  </div>
                  <div className="col-4 bd-join">
                    <div>
                      <img src={Image6} alt="No Image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </PageContainer>
    </div>
  );
};

export default Services;
