import React from 'react';
import { Link, useParams } from 'react-router-dom';

const MegaMenu = ({ source }) => {

    const params = useParams();

    function slugify(str) {
        return str
            .replace(/ &/g, '')
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/\//g, '')
            // .replace('-', '')
    }


    let megaContentView;
    if (source) {
        megaContentView = source.megaContent.map((item) => (
            <div className="mega-menu__column" key={item.heading}>
                <ul className="mega-menu__list">
                    {item.megaItems.map((subItem) => (
                        <li key={subItem.text}>
                            <Link to={`${subItem.url}`} >
                                <a>{subItem.text}</a>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        ));
    }



    return (
        <li className={"menu-item-has-children has-mega-menu " + source.text}>
            <Link to={`/${slugify(source.text)}`} className={slugify(source.text) == params.slug ? 'active-head' : ""}>
                {source.text}
            </Link>
            <div className="mega-menu">{megaContentView}</div>
        </li>
    );
};

export default MegaMenu;
