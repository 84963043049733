import React, { useEffect } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const Terms = () => {

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Terms and Conditions",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  return (
    <div>
      <Helmet>
        <title>Comprehensive Terms and Conditions by W-Hanson UK</title>
        <meta name="description" Content="Explore the detailed terms and conditions provided by W-Hanson UK. Ensure clarity and understanding before engaging with our services."></meta>
      </Helmet>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-section--custom">
            <div className="container">
              <div className="ps-section__header">
                <h1 className="text-center " style={{ fontWeight: "550" }}>W.HANSON GROUP TERMS & CONDITIONS OF SALE</h1>
              </div>
              <div className="ps-section__content term-section mt-40">
                <h3>ACCEPTANCE OF ORDER</h3>
                <ol>
                  <li>
                    Whilst great care is taken by us to ensure that the goods
                    supplied correspond with our customers wishes, your order is
                    accepted subject to the conditions specified below which
                    override all the other terms and conditions inconsistent
                    therewith, express, implied, statutory or otherwise and
                    wherever contained.
                  </li>
                  <li>
                    No variation of the contract shall be binding upon us unless
                    accepted in writing.
                  </li>
                  <li>
                    Our total liability hereunder for any loss (whether
                    consequential or otherwise) arising from whatever reason
                    including negligence, shall be limited to the contract price
                    for the goods except that in the case of consumer sales
                    within the meaning of the Sales of Goods (Implied Terms) Act
                    1973 nothing in this clause shall operate to restrict either
                    the exercise of any right conferred by the provisions of
                    sections 12, 13, 14 or 15 of the Sale of Goods Act 1893 as
                    amended by the Supply of Goods (Implied Terms) Act 1973 or
                    any liability we may have for breach of a condition or
                    warranty implied by these sections.
                  </li>
                </ol>

                <h3>REPRESENTATIONS</h3>
                <ol>
                  <li>
                    In accepting delivery of the goods you acknowledge that no
                    representations, whether oral or in writing have been made
                    by us, or by any officer, servant or agent of ours which
                    have induced you to enter into the contract for the purchase
                    of such goods.
                  </li>
                  <li>
                    No representation or warranty is made, given or to be
                    implied save for those expressly stated in these conditions.
                  </li>
                </ol>

                <h3>DESCRIPTION</h3>
                <ol>
                  <li>
                    Where goods are sold by description it is not a condition of
                    the contract nor is it warranted that the goods exactly
                    correspond with the description.
                  </li>
                </ol>

                <h3>FITNESS OF PURPOSE</h3>
                <ol>
                  <li>
                    It is not a condition of the contract, nor is it warranted
                    that goods sold are fit for any particular purposes whether
                    made known to us or not.
                  </li>
                  <li>
                    Notwithstanding the above, if we are satisfied that the
                    goods were defective in workmanship upon delivery and
                    provided that the notice of such defect is given to us
                    within three days after delivery of such goods, and the
                    goods are returned to us carriage paid, we will replace,
                    repair or refund the price (at our option).
                  </li>
                  <li>
                    Our warranty to repair does not include the cost of taking
                    out, reaffixing or making good other materials.
                  </li>
                  <li>
                    Where goods are not of our manufacture, our liability under
                    this section, shall not be greater than the manufacturers
                    liability to us.
                  </li>
                </ol>

                <h3>SAMPLES</h3>
                <ol>
                  <li>
                    It is not a condition of the contract, nor is it warranted,
                    that the goods supplied will correspond in any quality with
                    any sample or illustration supplied by us.
                  </li>
                  <li>
                    It is not a condition of the contract, nor is it warranted
                    that the goods shall be free of any defect rendering them
                    unmerchantable, which would not be apparent on reasonable
                    examination of the sample.
                  </li>
                </ol>

                <h3>PLANS</h3>
                <ol>
                  <li>
                    Where we provide a specially designed plan, no liability is
                    accepted arising from any inaccuracies or omissions in the
                    measurements given to us.
                  </li>
                  <li>
                    Any plan drawn by us is and remains our property and may not
                    be reproduced in whole or in part without our prior written
                    consent.
                  </li>
                </ol>

                <h3>AVAILABILITY</h3>
                <ol>
                  <li>
                    Goods sold subject to availability, we reserve the right to
                    supply similar goods in lieu or refund the price (at our
                    option) where those ordered are not available.
                  </li>
                  <li>
                    As timber is a natural product, the colour & grain of timber supplied may vary to the images on this site
                  </li>
                </ol>

                <h3>PRICE</h3>
                <ol>
                  <li>
                    Where goods are delivered by our own transport ex stock, the
                    price includes the cost of transport unless otherwise
                    specified.
                  </li>
                  <li>
                    If the goods sold are delivered by barge alongside, or at
                    any truck at station, any damage incurred is payable by you.
                  </li>
                  <li>
                    All prices quoted are those ruling at the date of quotation
                    or date of despatch, as appropriate, but are subject to
                    variation without notice.
                  </li>
                  <li>
                    We reserve the right to alter the contract price to take
                    account of any alteration in the rates of, any value added
                    tax or other sales tax now or hereinafter imposed.
                  </li>
                  <li>
                    We reserve the right to make a cutting charge in respect of
                    all goods cut to size by us.
                  </li>
                  <li>
                    We reserve the right to impose a nominal surcharge on all
                    accounts on or below a monthly value to be notified by us to
                    you from time to time.
                  </li>
                  <li>
                    We reserve the right to impose a surcharge where delivery
                    has to be made outside our normal hours of business.
                  </li>
                  <li>
                    We reserve the right to impose a surcharge if our driver has
                    to return without unloading through a breach of these
                    conditions.
                  </li>
                  <li>
                    Web prices may vary to in store
                  </li>
                </ol>

                <h3>PAYMENT</h3>
                <ol>
                  <li>
                    Accounts are due for payment no later than the last day of
                    the month following the date of invoice.
                  </li>
                  <li>
                    Credit may be allowed on such terms and conditions as the
                    Company shall at its absolute discretion from time to time
                    prescribe. The Company reserves the right to refuse to
                    accept orders on credit at any time and without giving any
                    reason. In the event of non payment in accordance with
                    credit terms, the whole of the price of the goods delivered
                    shall immediately become due and payable and the Company
                    reserves the right to withhold, suspend or cancel
                    outstanding deliveries.
                  </li>
                  <li>
                    The Buyer shall not be entitled to withhold payment of any
                    amount payable to the Company by reason of any dispute or
                    claim of the Buyer and in the case of any short delivery or
                    delivery of damaged goods, shall remain liable to pay the
                    full invoice price of all other goods delivered or available
                    for delivery.
                  </li>
                  <li>
                    The Company reserves the right to charge interest at 4%
                    above HSBC Bank base rate per annum and from time to time in
                    force on all overdue accounts, such interest being deemed to
                    accrue on a day to day basis from the due date for payment.
                  </li>
                  <li>
                    The Company reserves the right at any time at its discretion
                    to demand security for payment before continuing with or
                    delivering goods in satisfaction of any order
                    notwithstanding any subsisting agreement to provide credit
                    to the Buyer.
                  </li>
                  <li>
                    The Buyer will be deemed to have repudiated the contract and
                    all sums owing to the Company on any account shall become
                    due and payable if an Administrative Receiver, Receiver,
                    Manager or Administrator is appointed over the assets
                    undertaking or property of the Buyer, or a Winding Up or
                    Administration Order against the Buyer is made or
                    petitioned, or any Petition or Order in Bankruptcy against
                    the Buyer is presented or made, or the Buyer resolves to or
                    goes into Voluntary Liquidation ( other than for the
                    purposes of re-construction or amalgamation while solvent )
                    or calls a meeting of or makes arrangements or compositions
                    with creditors.
                  </li>
                  <li>
                    Refunds or credit notes will only be offered in accordance
                    with your statutory rights (which remain unaffected). Goods
                    must be returned carriage paid, clean and in sound condition
                    within 28 days from the date of invoice.
                  </li>
                </ol>

                <h3>TRANSFER OF PROPERTY</h3>
                <ol>
                  <li>
                    Risk in the Goods shall pass to the Buyer when the Goods are
                    delivered to, or collected by the Buyer or it’s Agent.
                  </li>
                  <li>
                    The ownership of the Goods sold by the Company to the Buyer
                    shall remain with the Company until the Buyer has paid the
                    price for those Goods. For the purpose of these terms all
                    liquidated sums owed by the Buyer to the Company on any
                    account or grounds whatsoever shall be deemed to form part
                    of the said price.
                  </li>
                  <li>
                    The Buyer is licensed by the Company to use or to agree to
                    sell the Goods delivered to the Buyer subject to the express
                    condition that the entire proceeds of any sale are held in
                    trust for the Company and are not mixed with other monies
                    paid into an overdrawn Bank Account and shall at all times
                    be identifiable as the Company’s money.
                  </li>
                  <li>
                    Until title to the goods passes:- The Buyer will hold the
                    goods as fiduciary agent and bailee for the Company; The
                    goods shall, subject to clause 10(c) be kept separate and
                    distinction from all other property of the Buyer and of
                    third parties and in good and substantial repair and
                    condition and be stored in such a way as to be clearly
                    identifiable as belonging to the Company and the Buyer will
                    not or will not allow any interference with any
                    identification marks or serial numbers on the goods, or the
                    packaging thereof. Without prejudice to any other rights the
                    Company may at any time revoke the power of sale and use
                    contained in clause 10(c) by notice to the Buyer if the
                    Buyer is in default in the payment of any sum whatsoever due
                    to the Company (whether in respect of the goods or any other
                    goods supplied at any time by it to the Buyer) or if the
                    Company has bona fide doubts as to the solvency of the
                    Buyer. The Buyer’s power of sale and use contained in clause
                    10(c) shall automatically cease upon the happening of any of
                    the events set out in clause 9(g) or if the Buyer takes or
                    suffers any similar action in consequence of debts or
                    carries out or undergoes any analogous act or proceeding
                    under foreign law and all sums owing to the Company on any
                    account shall become due and payable forthwith without
                    requirement for any notice to be given.
                  </li>
                  <li>
                    The Buyer shall place any of the goods in its possession or
                    under it’s control and unsold at the disposal of the Company
                    and the Company by its servants or agents shall be entitled
                    to enter upon any premises of the Buyer or any premises
                    under the Buyer’s control or to which the Buyer has a right
                    of access for the purpose of inspection, repossession and
                    removal of such goods at any time.
                  </li>
                </ol>
                <h3>RISK</h3>
                <ol>
                  <li>
                    While goods in your possession or under your control and
                    until the property therein has passed to you the goods shall
                    be at your risk and you shall indemnify us against any loss
                    or damage to the goods by payment in full of the price
                    thereof and against any claims arising out of injury or
                    damage attributable to the goods.
                  </li>
                  <li>
                    If at your request, we hold goods in our store, the same
                    will be at your risk.
                  </li>
                </ol>

                <h3>TIME OF DELIVERY</h3>
                <ol>
                  <li>
                    Time of delivery shall not be of the essence in the
                    contract.
                  </li>
                  <li>
                    Forward delivery dates are given in good faith, but are
                    contingent upon our having the goods held in stock for order
                    or upon the ability of our suppliers to keep their forecast
                    dates.
                  </li>
                  <li>
                    If delivery times are restricted by local traffic
                    regulations, it is the responsibility of the customer to
                    advise us of the details of the restrictions.
                  </li>
                </ol>

                <h3>PLACE OF DELIVERY</h3>
                <ol>
                  <li>
                    Unless otherwise stated, the place of delivery or collection
                    shall be our place of business.
                  </li>
                  <li>
                    Where goods are offered for delivery to site, our obligation
                    is to deliver as near to the site as a safe hard road
                    permits.
                  </li>
                  <li>
                    Where goods are required to be delivered to a place other
                    than that stated above, this must be specifically stated at
                    the time the order is given.
                  </li>
                  <li>
                    All the deliveries shall be made off the highway or by
                    passage or way to the rear or side of the delivery address.
                  </li>
                </ol>

                <h3> FACILITIES FOR DELIVERY</h3>
                <ol>
                  <li>
                    It is your responsibility to provide, free of charge the
                    labour and any machinery required to unload and stack the
                    goods at the time of the actual delivery
                  </li>
                  <li>
                    It is the purchasers responsibility to ensure the immediate
                    removal of the delivery made, and/or take such measures as
                    may be necessary to ensure the safety of road users.
                  </li>
                  <li>
                    The purchaser shall be deemed to agree to indemnify the
                    company against all claims.
                  </li>
                </ol>

                <h3>DAMAGE AND LOSS IN TRANSIT</h3>
                <ol>
                  <li>
                    We accept no liability for damage to goods occurring in
                    transit unless notified to us and, where a carrier is
                    concerned, such carrier within three days after delivery and
                    provided the goods haven’t been signed for “unexamined” or
                    “unchecked” or meanings to the same effect.
                  </li>
                  <li>
                    In the case of non delivery, we accept no liability of any
                    sort unless written notice of nondelivery is given to us
                    within seven days of posting the advice of despatch of
                    invoice for or credit note in respect of the goods.
                  </li>
                  <li>
                    Our liability for damage to or non delivery of goods duly
                    notified to us in accordance with the above shall in any
                    event be limited to replacement of the goods within a
                    reasonable time (at our option) refunding the price thereof
                    whether the damage or non delivery is due to negligence or
                    otherwise.
                  </li>
                </ol>

                <h3>INSTALMENT OF DELIVERIES</h3>
                <ol>
                  <li>
                    We reserve the right to deliver the goods by instalments and
                    in such event each instalment shall be treated as a separate
                    contract.
                  </li>
                  <li>
                    Despite the above, where goods are delivered by instalments,
                    deliveries of further instalments may be withheld until
                    goods supplied by earlier instalments have been paid for in
                    full.
                  </li>
                </ol>

                <h3>CONTAINERS</h3>
                <ol>
                  <li>
                    Sacks, cases, crates, drums, casks, pallets etc., where
                    returnable are charged to you upon delivery and will be
                    credited when the empties are received back at point of
                    origin, dryad in sound condition.
                  </li>
                  <li>
                    Where marked or invoiced as returnable, charges for crates,
                    case or packing will be refunded if they are returned to us
                    carriage paid, clean and in sound condition within 28 days
                    from the date delivered to our customer.
                  </li>
                  <ul>
                    <li>
                      Within 30 days of the date of our invoice, credit in full
                      will be allowed.
                    </li>
                    <li>
                      After this period and up to 90 days from date of invoice,
                      only 75% of the amount charged will be allowed.
                    </li>
                    <li>
                      After a lapse of 90 days from the date of our invoice
                      packages cannot be received back by us, and must be paid
                      for in full, no credit will be allowed.
                    </li>
                  </ul>
                </ol>

                <h3>COUNTERMANDS</h3>
                <ol>
                  <li>
                    Contracts and orders may not be cancelled by customers
                    except with our written permission.
                  </li>
                  <li>
                    Orders for goods made and cut specially to order cannot be
                    cancelled or changed if already machined.
                  </li>
                  <li>
                    We cannot accept the return of the goods obtained or made
                    specially to you instructions.
                  </li>
                  <li>
                    We cannot accept the return of the goods unless prior
                    arrangements have been agreed with our office.
                  </li>
                  <li>
                    We reserve the right to refuse countermands, which cannot be
                    accepted in the case of goods all ready for despatch, or in
                    the process of manufacture.
                  </li>
                  <li>
                    We reserve the right to make a 20% handling charge on goods
                    returned in good order and condition.
                  </li>
                </ol>

                <h3>CONSENTS</h3>
                <ol>
                  <li>
                    The obtaining of any consents for the installation of the
                    goods whether from local or other authorities and the fact
                    that the installation of the goods is in breach of any
                    provisions of any bye-laws, regulations or statutes shall
                    not be our responsibility and shall have no effect on the
                    validity of a contract of sales of our goods.
                  </li>
                </ol>
                <h3>JURISDICTION</h3>
                <ol>
                  <li>
                    Every contract to which these conditions apply shall be
                    construed and operate as an English contract, in accordance
                    with English Law and all disputes shall be submitted to the
                    jurisdiction of the English Courts.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default Terms;
