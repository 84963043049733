import React from 'react';
import FooterWidgets from './modules/FooterWidgets';
import FooterLinks from './modules/FooterLinks';
import FooterCopyright from './modules/FooterCopyright';
import { Link } from 'react-router-dom';

const FooterMarketPlace2 = () => (
    <footer className="ps-footer ps-footer--3 bg-gradient-blue">
        <div className="container">
            <div className="ps-block--site-features ps-block--site-features-2">
                <div className="ps-block__item justify-content-center">
                    <div>
                        <Link to="/terms-and-conditions" className='no-text'>
                            <div className="ps-block__left text-center">
                                <i className="icon-file-stats color-white"></i>
                            </div>
                            <div className="ps-block__right">
                                <h4 className='color-white'>Terms And Conditions</h4>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="ps-block__item justify-content-center">
                    <div>
                        <Link to="/return-policy" className='no-text'>
                            <div className="ps-block__left text-center">
                                <i className="icon-sync color-white"></i>
                            </div>
                            <div className="ps-block__right">
                                <h4 className='color-white'>Return Policy</h4>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="ps-block__item justify-content-center">
                    <div>
                        <Link to="/environmental-statement" className='no-text'>
                            <div className="ps-block__left text-center">
                                <i className="icon-credit-card color-white"></i>
                            </div>
                            <div className="ps-block__right">
                                <h4 className='color-white'>Environment Statement</h4>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="ps-block__item justify-content-center">
                    <div>
                        <Link to="/chain-of-custody" className='no-text'>
                            <div className="ps-block__left text-center">
                                <i className="icon-credit-card color-white"></i>
                            </div>
                            <div className="ps-block__right">
                                <h4 className='color-white'>Chain of Custody</h4>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="ps-block__item justify-content-center">
                    <div>
                        <Link to="/privacy-policy" className='no-text'>
                            <div className="ps-block__left text-center">
                                <i className="icon-bubbles color-white   "></i>
                            </div>
                            <div className="ps-block__right">
                                <h4 className='color-white'>Privacy Policy</h4>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <FooterWidgets />
            <FooterCopyright />
        </div>
    </footer>
);

export default FooterMarketPlace2;
