// metaHelper.js
import metaConfig from "./metaConfig"

export const getMeta = slug => {
  return (
    metaConfig[slug.slug] || {
      title: "Quality Building Materials in London & Harrow | W-hanson UK",
      description:
        "Discover top-quality building materials at W-hanson UK. Buy building supplies in London and Harrow. Find everything you need for your construction projects.",
      keywords:
        "building materials, buy building materials,building materials near me,building materials london,building supplies london,building materials harrow",
    }
  )
}
