import React, { useEffect, useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { notification } from "antd";
import axios from "axios";
import config from "../../config";

const Contact = () => {
    const navigate = useNavigate();
    // useEffect(() => {
    //     if (
    //         !localStorage.getItem("auth_user")
    //     ) {
    //         navigate("/login");
    //     }
    // }, []);

    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Contact Us",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setLoading] = useState(false);

    const onGetinTouch = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("description", message);
        formData.append("subject", subject);
        try {
            const response = await axios({
                method: "post",
                url: `${config.apiUrl}/get_in_touch`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                notification.open({
                    message: (
                        <div className="d-flex">
                            <div className="pr-20">
                                <i
                                    className="fa fa-check"
                                    style={{ color: "#4bb543" }}
                                ></i>
                            </div>
                            <div>Thank you for visiting Whanson Group</div>
                        </div>
                    ),
                    description: "Our Team Will Contact You Soon",
                });
                setLoading(false);
                setName("");
                setPhone("");
                setEmail("");
                setSubject("");
                setMessage("");
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <div>
            {isLoading && <Loader />}
            <Helmet>
                <title>
                    Contact W-Hanson UK for Expert Timber Solutions and Support
                </title>
                <meta
                    name="description"
                    Content="Reach out to W-Hanson UK for comprehensive timber products and services. Connect with us today to discuss your needs and solutions."
                ></meta>
            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--my-account">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-contact-info pb-0">
                        <div className="container">
                            <div className="ps-section__header">
                                <h3>Contact Us For Any Questions</h3>
                            </div>
                            <div className="ps-section__content">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
                                        <div className="ps-block--contact-info">
                                            <h4>Southall Branch</h4>
                                            <p>
                                                <p
                                                    className=""
                                                    style={{ color: "black" }}
                                                >
                                                    <i className="fa fa-map-marker pr-10"></i>
                                                    Southall, Uxbridge
                                                    Road,entrance on Greenford
                                                    Road,Southall,Middlesex, UB1
                                                    3EQ
                                                </p>
                                                <a
                                                    href="callto:020-8571-3161"
                                                    className="mb-10 "
                                                    style={{
                                                        color: "black",
                                                        fontSize: "17px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    <i className="fa fa-phone pr-10"></i>{" "}
                                                    020-8571-3161
                                                </a>
                                                <p>
                                                    <a
                                                        href="mailto:websales@w-hanson.co.uk"
                                                        style={{
                                                            color: "black",
                                                            fontSize: "17px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        <i className="fa fa-envelope pr-10"></i>
                                                        websales@w-hanson.co.uk
                                                    </a>
                                                </p>
                                                <div className="d-flex gap-2 justify-content-center">
                                                    <a
                                                        className="social-icon bg-blue"
                                                        href="https://www.facebook.com/whansonharrow/"
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-facebook font-white"></i>
                                                    </a>
                                                    <a
                                                        className="social-icon bg-blue"
                                                        href="https://www.instagram.com/whansonharrow/"
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-instagram font-white"></i>
                                                    </a>
                                                </div>
                                                <div className="text-center">
                                                    <a
                                                        className="btn-location"
                                                        href="https://maps.app.goo.gl/UXQDMB8uzA6GwinS7"
                                                        target="_blank"
                                                    >
                                                        Go to Location
                                                        <i className="fa fa-angle-right px-2"></i>
                                                    </a>
                                                </div>
                                            </p>
                                        </div>
                                        <div>
                                            <div>
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4966.274927864103!2d-0.35469720522274756!3d51.510694014564066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760d5e15c94bd7%3A0x9a1305f817da7192!2sSouthall%20UB1%203EQ%2C%20UK!5e0!3m2!1sen!2sin!4v1702451967000!5m2!1sen!2sin"
                                                    height="400"
                                                    allowfullscreen=""
                                                    loading="lazy"
                                                    className="w-100 border box-map-shadow"
                                                    referrerpolicy="no-referrer-when-downgrade"
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                        <div className="ps-block--contact-info">
                                            <h4>Harrow Branch</h4>
                                            <p>
                                                <p
                                                    className=""
                                                    style={{ color: "black" }}
                                                >
                                                    <i className="fa fa-map-marker pr-10"></i>
                                                    92-108 Greenford Road,
                                                    Harrow, Middlesex, HA1 3QL
                                                </p>
                                                <a
                                                    href="callto:020-8864-0822 "
                                                    className="mb-10  w-100"
                                                    style={{
                                                        color: "black",
                                                        fontSize: "17px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    <i className="fa fa-phone pr-10"></i>
                                                    020-8864-0822
                                                </a>
                                                <p>
                                                    <a
                                                        href="mailto:websales@w-hanson.co.uk"
                                                        style={{
                                                            color: "black",
                                                            fontSize: "17px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        <i className="fa fa-envelope pr-10"></i>
                                                        websales@w-hanson.co.uk
                                                    </a>
                                                </p>
                                                <div className="d-flex gap-2 justify-content-center">
                                                    <a
                                                        className="social-icon bg-blue"
                                                        href="https://www.facebook.com/whansonharrow/"
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-facebook font-white"></i>
                                                    </a>
                                                    <a
                                                        className="social-icon bg-blue"
                                                        href="https://www.instagram.com/whansonharrow/"
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-instagram font-white"></i>
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        href="https://maps.app.goo.gl/RAm7eyqJ2MZXPL8r9"
                                                        target="_blank"
                                                        className="btn-location"
                                                    >
                                                        Go to Location
                                                        <i className="fa fa-angle-right px-2"></i>
                                                    </a>
                                                </div>
                                            </p>
                                        </div>
                                        <div className="">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.516695338014!2d-0.3379942687492029!3d51.55876074469121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487613010954f09f%3A0xdc9bfcc8306acc88!2sGreenford%20Rd%2C%20Harrow%20HA1%203QL%2C%20UK!5e0!3m2!1sen!2sin!4v1702452159276!5m2!1sen!2sin"
                                                height="400"
                                                allowfullscreen=""
                                                className="w-100 border box-map-shadow"
                                                loading="lazy"
                                                referrerpolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
                                        <div
                                            className="ps-block--contact-info"
                                            style={{ maxWidth: "335px" }}
                                        >
                                            <h4>Northolt Branch</h4>
                                            <p className="">
                                                <p
                                                    className=""
                                                    style={{ color: "black" }}
                                                >
                                                    <i className="fa fa-map-marker pr-10"></i>
                                                    Northolt,Unit 2 Northolt
                                                    Trading Estate,Belvue
                                                    Road,Northolt, Middlesex,UB5
                                                    5QS,
                                                </p>
                                                <a
                                                    href="callto:020-8845-2173"
                                                    className="mb-10"
                                                    style={{
                                                        color: "black",
                                                        fontSize: "17px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    <i className="fa fa-phone pr-10"></i>
                                                    020-8845-2173
                                                </a>
                                                <p>
                                                    <a
                                                        href="mailto:websales@w-hanson.co.uk"
                                                        style={{
                                                            color: "black",
                                                            fontSize: "17px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        <i className="fa fa-envelope pr-10"></i>
                                                        websales@w-hanson.co.uk
                                                    </a>
                                                </p>
                                                <div className="d-flex gap-2 justify-content-center">
                                                    <a
                                                        className="social-icon bg-blue"
                                                        href="https://www.facebook.com/whansonharrow/"
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-facebook font-white"></i>
                                                    </a>
                                                    <a
                                                        className="social-icon bg-blue"
                                                        href="https://www.instagram.com/whansonharrow/"
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-instagram font-white"></i>
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        href="https://maps.app.goo.gl/hoRGAqqQyn12Uxbk6"
                                                        target="_blank"
                                                        className="btn-location"
                                                    >
                                                        Go to Location
                                                        <i className="fa fa-angle-right px-2"></i>
                                                    </a>
                                                </div>
                                            </p>
                                        </div>
                                        <div>
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.253988606687!2d-0.36275932413114775!3d51.545241507939146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487612c4a530a16f%3A0x3d5bd66a2fb98624!2s2%20Belvue%20Rd%2C%20Northolt%20UB5%205QS%2C%20UK!5e0!3m2!1sen!2sin!4v1702452345559!5m2!1sen!2sin"
                                                height="400"
                                                className="w-100 border box-map-shadow"
                                                allowfullscreen=""
                                                loading="lazy"
                                                referrerpolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ps-contact-form mt-5">
                    <div className="container">
                        <form
                            className="ps-form--contact-us"
                            onSubmit={onGetinTouch}
                        >
                            <h3>Get In Touch</h3>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Name *"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="email"
                                            placeholder="Email *"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Phone *"
                                            minLength={7}
                                            maxLength={15}
                                            value={phone}
                                            onChange={(e) => {
                                                const numericValue =
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    );
                                                setPhone(numericValue);
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Subject *"
                                            value={subject}
                                            onChange={(e) =>
                                                setSubject(e.target.value)
                                            }
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            rows="5"
                                            placeholder="About Your Product"
                                            value={message}
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group submit ">
                                <button className="ps-btn bg-blue color-white">
                                    Send message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

export default Contact;
