// metaConfig.js
const metaConfig = {
  "Softwood": {
    title: "Quality Softwood Suppliers | Buy Softwood Timber Near You",
    description:
      "Find premium white softwood and timber at W-hanson UK. Your trusted softwood suppliers near me. Buy softwoods for all your construction needs today!",
    keywords: "softwood suppliers near me",
  },
  "Firrings-Arris-Featheredge-Fencing": {
    title: "Firrings, Arris Rails & Featheredge Fencing | W-hanson UK",
    description:
      "Shop firrings, arris rails, and featheredge fencing at W-hanson UK. Quality fencing supplies for all your outdoor projects. Reliable, durable, and ready to use!",
    keywords: "softwood suppliers",
  },
  "Thermowood": {
    title: "Discover Premium Thermowood: Buy Now for Sustainable Construction",
    description:
      "Get this durable and sustainable Thermowood at W-Hanson UK. Enhance your projects with high-quality timber. Buy now for long-lasting performance.",
    keywords: "white softwood",
  },
  "C24-softwood-treated-PEFC": {
    title: "Buy PEFC Certified C24 Softwood Now: Quality Treated Timber",
    description:
      "Discover premium C24 softwood treated timber at W-Hanson UK. Get this PEFC certified wood for your construction projects. Buy now for reliable quality and durability.",
    keywords: "softwoods near me",
  },
  "Cheshire-Mouldings": {
    title: "Explore Cheshire Mouldings Collection: Get Premium Mouldings Now",
    description:
      "Discover exquisite Cheshire mouldings at W-Hanson UK. Elevate your space with our premium selection. Buy now for timeless elegance and quality craftsmanship.",
    keywords: "softwood timber",
  },
  "home-grown-eased-edge-kd-treated": {
    title: "Buy Home Grown Eased Edge KD Treated Timber Now | W-Hanson UK",
    description:
      "Discover the quality of our home-grown, KD treated timber. Get this premium product from W-Hanson UK for your construction needs. Buy now for durability and reliability.",
    keywords: "buy softwood",
  },
  CLS: {
    title: "Discover Versatile CLS Timber: Buy Now for Your Construction Needs",
    description:
      "Get this high-quality CLS timber at W-Hanson UK. Perfect for various construction projects. Buy now and enhance your building endeavors.",
    keywords: "",
  },
  "PAR-Contract-Softwood": {
    title: "Discover High-Quality PAR Softwood: Buy Now for Your Projects",
    description:
      "Get this premium PAR contract softwood at W-Hanson UK. Enhance your projects with top-quality timber. Buy now for reliable and durable construction materials.",
    keywords: "",
  },
  "PAR-European-Softwood": {
    title: "Buy Premium PAR European Softwood: Enhance Your Projects Today!",
    description:
      "Discover high-quality PAR European Softwood at W-Hanson UK. Get this top-grade timber now to elevate your construction projects. Buy now for unbeatable quality and performance.",
    keywords: "",
  },
  "PAR-European-Softwood": {
    title: "Enhance Your Spaces with PAR European Softwood Mouldings: Buy Now!",
    description:
      "Discover exquisite PAR European Softwood Mouldings at W-Hanson UK. Get this premium quality for your projects today. Buy now to elevate your interior design.",
    keywords: "",
  },
  "PAR-European-Softwood-Mouldings": {
    title: "Enhance Your Spaces with PAR European Softwood Mouldings: Buy Now!",
    description:
      "Discover exquisite PAR European Softwood Mouldings at W-Hanson UK. Get this premium quality for your projects today. Buy now to elevate your interior design.",
    keywords: "",
  },
  "pointed-pegs": {
    title: "Buy Pointed Pegs for Secure Construction Projects at W-Hanson UK",
    description:
      "Discover high-quality pointed pegs at W-Hanson UK. Get this essential construction material now for secure and reliable building projects.",
    keywords: "",
  },
  "sash-materials": {
    title: "Enhance Your Sash Projects: Get Quality Materials Now",
    description:
      "Discover top-notch sash materials at W-Hanson UK. Buy now to elevate your projects with durable and reliable materials.",
    keywords: "",
  },
  "shrinkwrapped-door-linings": {
    title: "Get Shrinkwrapped Door Linings for Easy Installation",
    description:
      "Discover hassle-free installation with our shrinkwrapped door linings. Buy now and streamline your construction projects.",
    keywords: "Shrinkwrapped Door Linings",
  },
  "MDF-Mouldings": {
    title: "Discover Premium MDF Mouldings: Buy Now at W-Hanson UK",
    description:
      "Get this high-quality MDF mouldings at W-Hanson UK. Enhance your projects with our durable and versatile options. Buy now for top-notch craftsmanship.",
    keywords: "mdf mouldings",
  },
  "MDF-Skirting-Archtrave": {
    title: "Upgrade Your Space with MDF Skirting & Architrave - Buy Now!",
    description:
      "Discover premium MDF skirting and architrave at W-Hanson UK. Get this high-quality trim for your home renovation projects. Buy now for stylish and durable solutions.",
    keywords: "mdf mouldings near me",
  },
  "MDF-Window-Boards": {
    title: "Enhance Your Windows with MDF Window Boards – Buy Now!",
    description:
      "Discover premium MDF window boards at W-Hanson UK. Get this durable solution for your windows. Buy now to upgrade your home!",
    keywords: "mdf skirting mouldings",
  },
  "MDF-Door-Stop-Misc": {
    title: "Buy MDF Door Stop & More: Enhance Your Interior Today",
    description:
      "Discover high-quality MDF door stop and miscellaneous items at W-Hanson UK. Get this essential for your interior projects. Buy now and upgrade your space!",
    keywords: "mdf window boards",
  },
  "MDF-Linings-sets": {
    title: "Find Premium MDF Skirting Boards Near You | W-Hanson UK",
    description:
      "Discover top-quality MDF timber mouldings at W-Hanson UK. Get this durable and stylish MDF skirting board now. Buy now and enhance your interior designs.",
    keywords:
      "mdf skirting board near me,mdf timber mouldings,mdf moulding manufacturers",
  },
  Insulation: {
    title: "Discover Effective Cavity Insulation: Buy Now at W-Hanson UK",
    description:
      "Get this premium cavity insulation solution from W-Hanson UK. Enhance your property's energy efficiency and comfort. Buy now for insulation that lasts.",
    keywords: "cavity insulation",
  },
  Celotex: {
    title: "Buy Celotex Insulation: Upgrade Your Project with W-Hanson UK",
    description:
      "Discover high-quality Celotex insulation at W-Hanson UK. Get this top-notch insulation solution now to enhance your construction projects.",
    keywords: "",
  },
  Fibreglass: {
    title:
      "Discover Premium Fibreglass Products: Buy Now for Quality Construction",
    description:
      "Get this high-quality fibreglass from W-Hanson UK for your construction needs. Enhance durability and performance. Buy now for top-notch materials.",
    keywords: "",
  },
  "Cavity-Insulation": {
    title: "Discover Effective Cavity Insulation: Buy Now for Energy Savings",
    description:
      "Get this top-quality cavity insulation at W-Hanson UK. Improve your home's energy efficiency and comfort. Buy now for exceptional insulation solutions.",
    keywords: "",
  },
  Polystyrene: {
    title: "Discover Quality Polystyrene: Buy Now for Insulation Solutions",
    description:
      "Get this high-quality polystyrene insulation at W-Hanson UK. Enhance your projects with our durable solutions. Buy now for top-notch performance.",
    keywords: "",
  },
  "Sheet-Material": {
    title: "Explore Quality Sheet Materials: Buy Now for Your Projects",
    description:
      "Discover a wide range of sheet materials at W-Hanson UK. Get this essential for construction and renovation projects. Buy now for top-notch quality.",
    keywords: "",
  },
  "Birch-Plywood": {
    title: "Get Premium Birch Plywood: Buy Now for Quality Construction",
    description:
      "Discover top-quality Birch Plywood near you at W-Hanson UK. Get this durable material for your projects. Buy now and elevate your construction with W-Hanson UK's premium products.",
    keywords: "birch plywood",
  },
  "Hardwood-Throughout-Plywood": {
    title: "Discover Premium Hardwood Plywood: Buy Now for Your Projects",
    description:
      "Get this high-quality hardwood plywood at W-Hanson UK. Enhance your projects with durable and reliable materials. Buy now for top-notch performance.",
    keywords: "birch plywood near me",
  },
  "Hardwood-Plywood-FSC": {
    title: "Shop Hardwood Plywood FSC Certified: Buy Now!",
    description:
      "Discover top-quality Hardwood Plywood FSC certified at W-Hanson UK. Get this premium timber for your projects now. Buy with confidence from W-Hanson UK.",
    keyword: "hardwood plywood",
  },
  "Shuttering-Plywood": {
    title: "Quality Shuttering Plywood: Buy Now for Construction Needs",
    description:
      "Discover reliable shuttering plywood at W-Hanson UK. Get this top-grade material for your construction projects. Buy now for durability and performance.",
    keyword: "shuttering plywood",
  },
  "Marine-Plywood": {
    title: "Buy Marine Plywood for Quality Construction Projects",
    description:
      "Discover top-grade Marine Plywood at W-Hanson UK. Get this durable plywood now for your marine and construction needs. Buy now for premium quality.",
    keyword: "marine plywood",
  },
  OSB: {
    title: "Get OSB Panels for Your Projects at W-Hanson UK",
    description:
      "Discover top-quality OSB panels at W-Hanson UK. Buy now for durable construction materials. Get this essential building supply for your projects today!",
    keyword: "marine plywood near me",
  },
  MDF: {
    title: "Discover Premium MDF: Buy Now for Quality Projects",
    description:
      "Get this high-quality MDF at W-Hanson UK. Enhance your projects with durable and versatile materials. Buy now for top-notch results.",
    keyword: "",
  },
  "Moisture-Resistant-MDF": {
    title: "Discover Moisture-Resistant MDF: Buy Now for Durable Solutions",
    description:
      "Get this high-quality moisture-resistant MDF at W-Hanson UK. Enhance your projects with durable solutions. Buy now for reliable performance.",
    keyword: "",
  },
  "Chipboard": {
    title: "Discover Quality Chipboard at W-Hanson UK: Buy Now!",
    description:
      "Get this versatile chipboard for your construction needs at W-Hanson UK. Explore our range and buy now for reliable performance.",
    keyword: "",
  },
  "Hardboard": {
    title: "Discover Durable Hardboard: Buy Now for Your Projects",
    description:
      "Get this reliable hardboard from W-Hanson UK. Perfect for various applications. Buy now and enhance your construction projects.",
    keyword: "",
  },
  "Hardie-Backer": {
    title: "High-Quality Hardie Backer Boards: Buy Now from W-Hanson UK",
    description:
      "Discover top-grade Hardie Backer boards at W-Hanson UK. Get this essential construction material for your projects. Buy now for superior durability and performance.",
    keyword: "",
  },
  "Iron-On-Edging": {
    title: "Enhance Your Projects with Iron-On Edging: Buy Now!",
    description:
      "Discover high-quality iron-on edging at W-Hanson UK. Get this durable solution for your construction needs. Buy now for top-notch results.",
    keyword: "",
  },
  "Melamine-Faced-MDF": {
    title: "Buy High-Quality Melamine-Faced MDF at W-Hanson UK",
    description:
      "Discover top-quality Melamine-Faced MDF at W-Hanson UK. Get this durable material for your construction projects. Buy now for excellent results!",
    keyword: "",
  },
  "Flexcell": {
    title: "Enhance Your Projects with Flexcell: Buy Now at W-Hanson UK",
    description:
      "Discover the innovative Flexcell solution at W-Hanson UK. Get this versatile product now to elevate your construction projects.",
    keyword: "",
  },
  "Correx-Protection": {
    title: "Protect Your Surfaces with Correx: Buy Now at W-Hanson UK",
    description:
      "Discover high-quality Correx protection products at W-Hanson UK. Get this durable solution for safeguarding surfaces. Buy now for peace of mind.",
    keyword: "",
  },
  "Glues-Sealants-Chemicals": {
    title: "Shop Quality Glues, Sealants & Chemicals at W-Hanson UK",
    description:
      "Discover a wide range of glues, sealants, and chemicals at W-Hanson UK. Buy now to get top-quality products for your projects.",
    keyword: "glues sealants chemicals",
  },
  "CT1": {
    title: "Discover CT1 Adhesive: Buy Now for Reliable Bonding Solutions",
    description:
      "Get this top-quality adhesive from W-Hanson UK. Buy now for reliable bonding solutions and enhance your construction projects.",
    keyword: "",
  },
  "Evo-Stik": {
    title: "Get Evo-Stik Adhesives for Reliable Bonding | Buy Now",
    description:
      "Discover reliable Evo-Stik adhesives at W-Hanson UK. Get this trusted brand for all your bonding needs. Buy now for strong and durable results.",
    keyword: "",
  },
  "Bond-It": {
    title: "Buy Bond-It Adhesives: Secure Your Projects with W-Hanson UK",
    description:
      "Discover high-quality Bond-It adhesives at W-Hanson UK. Get this reliable solution now to ensure the durability of your projects. Buy now and experience the difference.",
    keyword: "",
  },
  "OB1": {
    title: "Get OB1: Buy Now for Premium Construction Solutions",
    description:
      "Discover OB1, the ultimate construction solution at W-Hanson UK. Get this premium product now for your building needs.",
    keyword: "",
  },
  "Metalwork": {
    title: "Discover Quality Metalwork at W-Hanson UK - Buy Now!",
    description:
      "Get this premium metalwork selection at W-Hanson UK. Explore our range and buy now for durable solutions to your construction needs.",
    keyword: "",
  },
  "Joist-Hangers": {
    title: "Buy Joist Hangers at W-Hanson UK for Secure Construction",
    description:
      "Discover a wide selection of joist hangers at W-Hanson UK. Get this essential hardware for your construction needs. Buy now and ensure secure building projects.",
    keyword: "joist hangers,buy joist hangers",
  },
  "Jiffy-Hangers": {
    title: "Buy Jiffy Hangers for Secure Installations at W-Hanson UK",
    description:
      "Discover reliable Jiffy Hangers at W-Hanson UK. Get this essential hardware for secure and efficient installations. Buy now for your construction needs.",
    keyword: "Jiffy Hangers",
  },
  "Beading": {
    title: "Shop Premium Beading: Buy Now at W-Hanson UK",
    description:
      "Discover top-quality beading options at W-Hanson UK. Get this essential accessory for your projects. Buy now for unbeatable quality and service.",
    keyword: "",
  },
  "Ties": {
    title:
      "Get Premium Ties for Construction Projects - Buy Now From W-Hanson Uk",
    description:
      "Discover a wide range of construction ties at W-Hanson UK. Enhance your projects with our durable and reliable ties. Buy now for quality you can trust.",
    keyword: "",
  },
  "Restraint-Straps": {
    title: "Secure Your Build: Restraint Straps Available Now",
    description:
      "Discover durable restraint straps at W-Hanson UK. Get this essential construction component now for added safety and stability. Buy now and secure your project.",
    keyword: "",
  },
  "Reinforcement-Mesh": {
    title: "Discover Quality Reinforcement Mesh: Buy Now from W-hanson UK",
    description:
      "Get this durable reinforcement mesh at W-Hanson UK. Strengthen your construction projects with our high-quality mesh. Buy now for reliable performance.",
    keyword: "",
  },
  "Metalwork-Misc": {
    title: "Quality Metalwork Accessories: Buy Now for Your Projects",
    description:
      "Discover a wide range of metalwork accessories at W-Hanson UK. Get this essential equipment for your construction needs. Buy now for reliable performance.",
    keyword: "",
  },
  "Aggregates-Heavyside": {
    title: "Shop Quality Aggregates & Heavyside Materials at W-Hanson UK",
    description:
      "Discover a wide range of aggregates and heavyside materials at W-Hanson UK. Get this essential supplies for your construction projects. Buy now for top-quality products and reliable service.",
    keyword: "aggregates,buy aggregates,aggregates near me,aggregate suppliers near me,aggregate stone near me",
  },
  "Bulk-Bags": {
    title: "Buy Bulk Bags: High-Quality Solutions at W-Hanson UK",
    description:
      "Discover versatile bulk bags at W-Hanson UK. Get this essential solution for your construction needs now. Order yours today for reliable performance.",
    keyword: "",
  },
  "Mini-Bags": {
    title: "Mini Bags- Buy Now for Convenient Building Solutions",
    description:
      "Discover versatile mini bags at W-Hanson UK. Get this convenient option for your building needs. Buy now for easy transportation and storage.",
    keyword: "",
  },
  "Cement": {
    title: "Buy Quality Cement at W-Hanson UK: Enhance Your Projects Today",
    description:
      "Discover top-grade cement for your construction needs at W-Hanson UK. Get this essential building material now and elevate your projects to the next level.",
    keyword: "buy cement,cement near me,",
  },
  "Top-Soil": {
    title: "Buy Quality Top Soil: Discover Rich Soil for Your Garden",
    description:
      "Get this nutrient-rich top soil at W-Hanson UK. Enhance your garden with our premium soil. Buy now for lush, healthy plants.",
    keyword: "top soil bulk bag",
  },
  "Air-Bricks": {
    title: "Buy Air Bricks for Ventilation: Enhance Your Space",
    description:
      "Discover a wide range of air bricks at W-Hanson UK. Get this essential ventilation solution now to improve airflow in your property.",
    keyword: "",
  },
  "Aco-Channel": {
    title: "Buy Aco Channel Drainage Systems Now from W-Hanson Uk",
    description:
      "Discover Aco Channel drainage systems at W-Hanson UK. Get this effective solution for water management. Buy now for reliable and durable drainage performance.",
    keyword: "",
  },
  "bricks": {
    title: "Buy Air Bricks Near Me at W-Hanson UK for Quality Construction",
    description:
      "Discover and buy now high-quality air bricks near me at W-Hanson UK. Get this essential building material for your construction needs today!",
    keyword: "air bricks near me",
  },
  "concrete-blocks": {
    title: "Buy Concrete Blocks Near Me: Quality Concrete Products Now",
    description:
      "Discover top concrete blocks and products at W-Hanson UK. Get this durable concrete near me for your projects. Buy now for quality you can trust.",
    keyword: "Concrete Blocks,concrete near me,concrete products",
  },
  "lightweight-blocks": {
    title: "Buy Lightweight Blocks Now for Your Construction Needs",
    description:
      "Discover lightweight blocks at W-Hanson UK. Get this essential building material for your projects. Buy now for quality and reliability in every block.",
    keyword: "concrete blocks near me,Lightweight Blocks",
  },
  "Fixings": {
    title: "Discover Top Fixings for Projects Buy Now at W-Hanson UK",
    description:
      "Get this wide range of fixings at W-Hanson UK. Discover top-quality products for your construction needs. Buy now for reliable and durable performance.",
    keyword: "",
  },
  "Timco-Bags": {
    title: "Discover Timco Bags at W-Hanson UK Buy Now for Quality",
    description:
      "Get this high-quality Timco Bags at W-Hanson UK. Buy now and discover durable bags for all your building needs. Perfect for construction projects.",
    keyword: "",
  },
  "Timco-Coach-Bolts": {
    title: "Discover Quality Timco Coach Bolts for Your Projects",
    description:
      "Get this premium Timco Coach Bolts at W-Hanson UK. Perfect for your construction needs. Discover durable and reliable fasteners. Shop now for quality you can trust.",
    keyword: "",
  },
  "Samac-Screws": {
    title: "Buy Samac Screws for All Projects at W-Hanson UK",
    description:
      "Get this high-quality Samac screws at W-Hanson UK for your construction needs. Discover durable and reliable screws for all your projects. Shop now!",
    keyword: "samac screws",
  },
  "Drywall-Screws": {
    title: "Discover Top-Quality Drywall Screws for Your Projects",
    description:
      "Get this premium selection of drywall screws at W-Hanson UK. Ideal for all your construction needs. Find reliable and durable fasteners for perfect results.",
    keyword: "Drywall Screws",
  },
  "Studding": {
    title: "Explore Quality Studding at W-Hanson UK - Discover Now!",
    description:
      "Get this essential studding material from W-Hanson UK. Explore our range and find the perfect fit for your construction needs.",
    keyword: "",
  },
  "Plasterboard-Rawlplug-Fixings": {
    title: "Find Plasterboard Rawlplug Fixings at W-Hanson UK",
    description:
      "Explore a variety of plasterboard rawlplug fixings at W-Hanson UK. Enhance your construction projects with these essential supplies.",
    keyword: "plasterboard fixings,",
  },
  "Masonry-Nails": {
    title: "Find High-Quality Masonry Nails at W-Hanson UK",
    description:
      "Explore durable masonry nails at W-Hanson UK. Enhance your projects with these reliable construction supplies.",
    keyword: "Masonry Nails",
  },
  "Misc-Fixings-Ironmongery": {
    title: "Shop Diverse Ironmongery & Misc Fixings at W-Hanson UK",
    description:
      "Explore a wide range of ironmongery and miscellaneous fixings at W-Hanson UK. Find quality products for all your construction needs.",
    keyword: "",
  },
  "TIMco-Firmahold": {
    title: "Find TIMco Firmahold Screws & Fasteners at W-Hanson UK",
    description:
      "Discover a range of TIMco Firmahold fasteners at W-Hanson UK. Find reliable solutions for your construction and DIY projects.",
    keyword: "",
  },
  "TIMco-Nails": {
    title: "Get Quality TIMco Nails for Secure Construction | W-Hanson UK",
    description:
      "Shop TIMco nails at W-Hanson UK for dependable fasteners tailored to meet your construction requirements.",
    keyword: "",
  },
  "TIMco-Screws": {
    title: "High-Quality TIMco Screws Available at W-Hanson UK",
    description:
      "Discover a wide range of TIMco screws at W-Hanson UK. From wood screws to specialist fasteners, find the perfect solution for your project.",
    keyword: "",
  },
  "TIMco-Shield-Protective-Film": {
    title: "Quality TIMco Shield Protective Film at W-Hanson UK",
    description:
      "Discover high-quality TIMco Shield Protective Film at W-Hanson UK. Protect your surfaces with durable, reliable solutions.",
    keyword: "",
  },
  "TIMco-Cutting": {
    title: "Precision TIMco Cutting Tools at W-Hanson UK",
    description:
      "Discover high-quality TIMco cutting tools at W-Hanson UK. Browse our range of precision tools for all your cutting needs.",
    keyword: "timco cutting",
  },
  "TIMco-Drilling-and-Driver-Bits": {
    title:
      "Discover High-Quality TIMco Drilling and Driver Bits at W-Hanson UK",
    description:
      "Explore a wide range of TIMco drilling and driver bits at W-Hanson UK. Find durable tools designed for precision and efficiency.",
    keyword: "",
  },
  "TIMco-Misc": {
    title: "High-Quality TIMco Miscellaneous Products at W-Hanson UK",
    description:
      "Explore a wide range of TIMco miscellaneous products at W-Hanson UK. Discover top-notch quality and competitive prices for all your construction needs.",
    keyword: "",
  },
  "Drywall-Products": {
    title: "Discover Quality Drywall Products at W-Hanson UK",
    description:
      "Explore a wide range of drywall products at W-Hanson UK. From plasterboards to insulation solutions, find everything you need for your construction projects.",
    keyword: "Drywall Products",
  },
  "Plasterboard": {
    title: "Quality Plasterboard Solutions for Reliable Construction Projects",
    description:
      "Explore high-quality plasterboard products at W-Hanson UK. Enhance your construction with durable materials trusted by professionals.",
    keyword: "Plasterboard",
  },
  "Plaster": {
    title: "High-Quality Thistle Plaster Products | W-Hanson UK",
    description:
      "Discover top-notch Thistle hardwall plaster and Thistle Multi Finish Plaster at W-Hanson UK. Explore our range for superior plastering solutions.",
    keyword: "thistle hardwall plaster,Thistle Multi Finish Plaster",
  },
  "Concrete-Products": {
    title: "High-Quality Concrete Products for Every Construction Need",
    description:
      "Discover a wide range of concrete products at W-Hanson UK. From foundations to finishing touches, find the perfect solution for your construction projects.",
    keyword: "",
  },
  "Pre-Stressed-Lintels": {
    title: "High-Quality Pre-Stressed Lintels at W-Hanson UK",
    description:
      "Discover durable pre-stressed lintels at W-Hanson UK. Explore our range designed for strength and reliability. Order yours today!",
    keyword: "",
  },
  "Copings": {
    title: "High-Quality Concrete Coping Stone & Lintels | W-Hanson UK",
    description:
      "Discover durable concrete coping stone and prestressed concrete lintels at W-Hanson UK. Explore our range for your construction needs.",
    keyword: "concrete coping stone,prestressed concrete lintel",
  },
  "Pier-Caps": {
    title: "Enhance Your Property with High-Quality Pier Caps - W-Hanson UK",
    description:
      "Discover premium pier caps crafted for durability and style at W-Hanson UK. Elevate your property with our exquisite range.",
    keyword: "Concrete Pier Cap",
  },
  "Padstones": {
    title: "High-Quality Padstones Available at W-Hanson UK",
    description:
      "Discover top-notch padstones near you with W-Hanson UK, your trusted supplier for high-quality building materials.",
    keyword: "Padstones,padstones near me,padstone suppliers near me",
  },
  "Concrete-Misc": {
    title: "Get Quality Concrete Products for Varied Projects | W-Hanson UK",
    description:
      "Discover a wide range of concrete miscellanea for your construction needs at W-Hanson UK. Explore our offerings today!",
    keyword: "",
  },
  "Decking": {
    title: "Premium Decking Solutions for Your Home | W-Hanson UK",
    description:
      "Discover premium decking solutions for your home with W-Hanson UK. Enhance your outdoor space with our quality materials and expert craftsmanship.",
    keyword: "",
  },
  "Softwood-Decking": {
    title: "Quality Softwood Decking Available at W-Hanson UK",
    description:
      "Explore our range of premium softwood decking at W-Hanson UK. Choose from various styles and finishes to enhance your outdoor space.",
    keyword: "",
  },
  "Roofing": {
    title: "Buy Quality Roofing Materials at W-Hanson UK",
    description:
      "Discover a wide range of roofing materials at W-Hanson UK. Browse our selection for top-quality solutions.",
    keyword: "roofing materials",
  },
  "Steel-Lintels": {
    title:
      "High-Quality Steel Lintels for Reliable Construction | W-Hanson UK",
    description:
      "Discover durable steel lintels at W-Hanson UK. Ensure strength and longevity for your construction projects with our premium selection.",
    keyword: "",
  },
  "Lead": {
    title: "Premium Lead Products Available at W-Hanson UK",
    description:
      "Find superior lead products at W-Hanson UK, catering to various industrial needs. Trust our expertise for durable and reliable lead solutions.",
    keyword: "",
  },
  "PVC-Soffit-Fascia": {
    title: "Premium PVC Soffit and Fascia Products at W-Hanson UK",
    description:
      "Discover high-quality PVC soffit and fascia solutions at W-Hanson UK. Explore our range designed for durability and aesthetic appeal.",
    keyword: "",
  },
  "Roof-Felt": {
    title: "High-Quality Roof Felt Solutions - W-Hanson UK",
    description:
      "Discover durable and affordable roof felt solutions at W-Hanson UK. Explore our range of products designed for longevity and reliability.",
    keyword: "",
  },
  "Torch-On-Felt": {
    title: "Get High-Quality Torch-On Felt Solutions | W-Hanson UK",
    description:
      "Discover top-notch torch-on felt roofing solutions at W-Hanson UK. Reliable, durable, and expertly installed for lasting protection.",
    keyword: "",
  },
  "Cromar-Building-Products": {
    title: "Discover Quality Cromar Building Products at W-Hanson UK",
    description:
      "Explore a wide range of Cromar building products at W-Hanson UK. Find everything you need for your construction projects.",
    keyword: "",
  },
  "GRP": {
    title: "High-Quality GRP Products by W-Hanson UK - Durable Solutions",
    description:
      "Explore a wide range of GRP products at W-Hanson UK. Our durable solutions ensure reliability and performance for your projects.",
    keyword: "",
  },
  "Paint": {
    title: "Quality Paint Products and Supplies at W-Hanson UK",
    description:
      "Discover a wide selection of premium paint products and supplies at W-Hanson UK. Explore our range today for your painting needs.",
    keyword: "",
  },
  "Undercoat-Primer": {
    title: "Enhance Surface Preparation with W-Hanson UK Undercoat Primer",
    description:
      "Discover high-quality undercoat primer from W-Hanson UK. Achieve optimal surface preparation for your projects. Order now!",
    keyword: "",
  },
  "Emulsion-Vinyl-Eggshell": {
    title: "Premium Emulsion Vinyl Eggshell Paints | W-Hanson UK",
    description:
      "Discover high-quality Emulsion Vinyl Eggshell paints at W-Hanson UK. Achieve smooth finishes with our premium selection.",
    keyword: "",
  },
  "Turps-Meths-White-Spirit-Misc": {
    title: "Shop Turps, Meths, White Spirit & More at W-Hanson UK",
    description:
      "Discover a wide range of turps, meths, white spirit, and miscellaneous products at W-Hanson UK. Quality supplies for your needs.",
    keyword: "",
  },
  "Masonry-Paint": {
    title: "Quality Masonry Paint Products for Your Projects | W-Hanson UK",
    description:
      "Discover premium masonry paint solutions at W-Hanson UK. Enhance your projects with our durable and vibrant paint range.",
    keyword: "",
  },
  "Gloss": {
    title: "Discover Quality Gloss Products at W-Hanson UK",
    description:
      "Explore a wide range of high-quality gloss products at W-Hanson UK. Find durable solutions for your projects today.",
    keyword: "",
  },
  "Plumbing": {
    title: "Find Quality Plumbing Materials at W-Hanson UK",
    description:
      "Discover top-notch plumbing materials near you at W-Hanson UK. Explore our wide selection online today!",
    keyword: "plumbing materials,plumbing materials near me",
  },
  "Solvent-Waste": {
    title: "Quality Solvent Waste Plumbing Solutions by W-Hanson UK",
    description:
      "Discover top-tier solvent waste plumbing solutions at W-Hanson UK. Explore our range of products designed for efficiency and durability.",
    keyword: "",
  },
  "Rainwater": {
    title: "Quality Rainwater Plumbing Solutions by W-Hanson UK",
    description:
      "Discover expert rainwater plumbing services in the UK with W-Hanson UK. Reliable solutions for residential and commercial needs.",
    keyword: "",
  },
  "Soil": {
    title: "Expert Plumbing Solutions by W-Hanson UK",
    description:
      "Discover top-tier plumbing services in the UK by W-Hanson. Enhance your soil system with our trusted expertise. Satisfaction guaranteed!",
    keyword: "",
  },
  "Underground": {
    title: "Discover Quality Underground Plumbing Products at W-Hanson UK",
    description:
      "Explore a wide range of underground plumbing products at W-Hanson UK. Find durable solutions for your plumbing needs.",
    keyword: "",
  },
  "Push-Fit-Fittings": {
    title: "Quality Push-Fit Fittings for Efficient Plumbing Solutions",
    description:
      "Discover a wide range of push-fit fittings at W-Hanson UK. Ensure seamless plumbing installations with our reliable products.",
    keyword: "",
  },
  "Manhole-Covers-Chambers": {
    title: "High-Quality Manhole Covers and Chambers at W-Hanson UK",
    description:
      "Discover durable plumbing products at W-Hanson UK. Explore our range of manhole covers and chambers designed for reliability and longevity.",
    keyword: "",
  },
  "Brass-Compression-Fittings": {
    title: "Quality Brass Compression Fittings for Plumbing | W-Hanson UK",
    description:
      "Discover a wide range of brass compression fittings for plumbing at W-Hanson UK. Ensure quality and reliability with our products.",
    keyword: "",
  },
  "End-Feed-Fittings": {
    title: "High-Quality End Feed Fittings for Plumbing - W-Hanson UK",
    description:
      "Discover top-notch end feed fittings for plumbing projects at W-Hanson UK. Browse our range online and find the perfect fit for your needs today!",
    keyword: "",
  },
  "Plumbing-Misc": {
    title:
      "Quality Plumbing Products at W-Hanson UK - Buy Misc Plumbing Items",
    description:
      "Discover a wide selection of plumbing products at W-Hanson UK. From essential tools to miscellaneous items, find everything you need for your plumbing projects.",
    keyword: "",
  },
  "Solder-Ring-Fittings": {
    title: "Quality Solder Ring Fittings at W-Hanson UK - Buy Now",
    description:
      "Explore a wide range of solder ring fittings at W-Hanson UK. Find high-quality plumbing solutions for your projects.",
    keyword: "",
  },
  "General-Ironmongery": {
    title: "Quality General Ironmongery Products at W-Hanson UK",
    description:
      "Discover a wide range of premium general ironmongery products at W-Hanson UK. From hinges to handles, find durable solutions for your projects.",
    keyword: "",
  },
  "Locks": {
    title: "Secure Your Property with High-Quality Locks | W-Hanson UK",
    description:
      "Explore top-notch locks at W-Hanson UK. Ensure your property's security with our durable and reliable lock solutions. Discover more online today!",
    keyword: "",
  },
  "Doors": {
    title: "Quality Doors for Every Home - W-Hanson UK",
    description:
      "Discover a wide range of high-quality doors at W-Hanson UK. Enhance your home's appeal with our durable and stylish door solutions.",
    keyword: "",
  },
  "Ply-Flush-Doors": {
    title: "High-Quality Ply Flush Doors - Explore W-Hanson UK's Range",
    description:
      "Discover premium ply flush doors at W-Hanson UK. Browse our selection for durability, style, and affordability.",
    keyword: "",
  },
  "FL&B-Doors": {
    title: "Premium FL&B Doors for Your UK Home - W-Hanson UK",
    description:
      "Discover high-quality FL&B doors for homes in the UK. Explore stylish designs and durability at W-Hanson UK.",
    keyword: "",
  },
  "L&B-Doors": {
    title: "Get Premium Quality L&B Doors Available at W-Hanson UK",
    description:
      "Discover a wide selection of L&B Doors at W-Hanson UK. Explore our range for superior craftsmanship and lasting durability.",
    keyword: "",
  },
  "Door-Blanks": {
    title: "Premium Door Blanks for Reliable Security Solutions - W-Hanson UK",
    description:
      "Discover high-quality door blanks at W-Hanson UK. Ensure security with our durable and reliable options. Explore our range today!",
    keyword: "",
  },
  "Tools": {
    title: "Buy Quality Tools for Every Job - W-Hanson UK",
    description:
      "Discover a comprehensive range of high-quality tools at W-Hanson UK. From power tools to hand tools, find everything you need for your projects.",
    keyword: "",
  },
  "Dekton-Tools": {
    title: "Discover Top Dekton Tools at W-Hanson UK",
    description:
      "Explore a wide range of high-quality Dekton tools at W-Hanson UK. Find durable solutions for your projects.",
    keyword: "",
  },
  "General-Hand-Tools": {
    title: "Find Top General Hand Tools at W-Hanson UK",
    description:
      "Upgrade your toolkit with general hand tools from W-Hanson UK. Quality tools designed to meet all your project requirements.",
    keyword: "",
  },
  "Polythene": {
    title: "GetHigh-Quality Polythene DPC Solutions at W-Hanson UK",
    description:
      "Discover superior polythene DPC products at W-Hanson UK. Ensure robust damp proofing with our trusted solutions. Visit us today!",
    keyword: "",
  },
  "Tapes": {
    title: "Quality Tapes for Every Solution- W-Hanson UK",
    description:
      "Discover a wide range of high-quality tapes at W-Hanson UK. From industrial to household use, find the perfect tape for your needs.",
    keyword: "",
  },
  "Masking-Tape": {
    title: "High-Quality Masking Tape by W-Hanson UK | Buy Now!",
    description:
      "Discover premium masking tape solutions at W-Hanson UK. Ideal for professional and DIY use. Explore our range and shop online today.",
    keyword: "",
  },
  "Gaffa-Tape": {
    title: "Buy Premium Quality Gaffa Tape - W-Hanson UK",
    description:
      "Discover durable gaffa tape at W-Hanson UK. Perfect for repairs, crafts, and more. Explore our range of high-quality adhesive tapes today.",
    keyword: "",
  },
  "Low-Tack-Tape": {
    title:
      "Low Tack Tape Solutions by W-Hanson UK | Quality Adhesive Products",
    description:
      "Discover high-quality low tack tape solutions at W-Hanson UK. Explore our range of adhesive products designed for various applications.",
    keyword: "",
  },
  "Packaging-Tape": {
    title: "High-Quality Packaging Tape for Secure Shipments | W-Hanson UK",
    description:
      "Discover durable packaging tape solutions at W-Hanson UK. Ensure your parcels stay secure during transit with our reliable tape options.",
    keyword: "",
  },
  "Scrim": {
    title: "Buy Quality Scrim Products for Your Construction Needs",
    description:
      "Discover premium scrim products at W-Hanson UK. Our range ensures durability and reliability for all construction projects.",
    keyword: "",
  },
  "General-Decorating": {
    title: "Quality General Decorating Supplies at W-Hanson UK",
    description:
      "Discover a wide range of general decorating supplies at W-Hanson UK. From essentials to specialty items, find everything you need for your decorating projects.",
    keyword: "",
  },
  "Sanding": {
    title: "Quality Sanding Products for Every Surface - W-Hanson UK",
    description:
      "Discover a wide range of sanding products at W-Hanson UK. Perfect for achieving smooth finishes on various surfaces.",
    keyword: "",
  },
  "Tetrion-Filler": {
    title:
      "Buy Premium Tetrion Filler at W-Hanson UK - Ideal for Perfect Surfaces",
    description:
      "Discover high-quality Tetrion Filler at W-Hanson UK. Achieve flawless finishes on any surface with our premium products.",
    keyword: "",
  },
  "Decorating-Tools": {
    title: "Quality Decorating Tools for Every Project - W-Hanson UK",
    description:
      "Discover a wide range of high-quality decorating tools at W-Hanson UK. From brushes to rollers, find everything you need for your projects.",
    keyword: "",
  },
  "Scrapers-Blades": {
    title: "High-Quality Scrapers and Blades - W-Hanson UK",
    description:
      "Discover top-grade scrapers and blades at W-Hanson UK. Browse our range for superior performance and durability. Order now!",
    keyword: "",
  },
  "Cleaning-preperation": {
    title: "Quality Cleaning Preparation Products by W-Hanson UK",
    description:
      "Discover a wide range of cleaning preparation solutions at W-Hanson UK. Ensure top-quality results with our trusted products.",
    keyword: "",
  },
  "Curtis-Holt-Loctite": {
    title: "High-Quality Loctite Products at W-Hanson UK",
    description:
      "Explore a wide range of superior Loctite products at W-Hanson UK. Discover solutions tailored for every need at competitive prices.",
    keyword: "",
  },
  "Hilton-Banks": {
    title: "Quality Hilton Banks by W-Hanson UK | Buy Now",
    description:
      "Discover top-notch Hilton Banks at W-Hanson UK. Explore our range and make your purchase today for premium quality and reliability.",
    keyword: "",
  },
  "Tou-Pret-Fillers": {
    title: "Quality Tou-Pret Fillers by W-Hanson UK - Buy Online Today",
    description:
      "Discover top-quality Tou-Pret fillers at W-Hanson UK. Explore our range and find the perfect products for your needs. Order now!",
    keyword: "",
  },
  "Garden-Home-Maintenance": {
    title: "Buy Quality Garden Maintenance Products at W-Hanson UK",
    description:
      "Explore a wide range of garden maintenance products at W-Hanson UK. Find everything you need for your garden and home upkeep.",
    keyword: "garden maintenance products",
  },
  "Wheelbarrows": {
    title: "Shop Quality Wheelbarrows for Efficient Material Handling ",
    description:
      "Discover durable wheelbarrows designed for reliability and efficiency at W-Hanson UK. Explore our range today!",
    keyword: "",
  },
  "Brooms": {
    title: "Buy Quality Brooms for Every Cleaning Task - W-Hanson UK",
    description:
      "Discover a wide range of brooms designed for efficiency and durability at W-Hanson UK. Perfect for homes and businesses alike.",
    keyword: "",
  },
  "Shovels-Forks": {
    title: "Quality Shovels and Forks at W-Hanson UK - Explore Our Range",
    description:
      "Discover a wide selection of durable shovels and forks at W-Hanson UK. Perfect for gardening, construction, and more. Browse now.",
    keyword: "",
  },
  "Garden-Misc": {
    title: "Transform Your Garden with W-Hanson UK's Diverse Range",
    description:
      "Explore unique garden solutions at W-Hanson UK. From tools to decor, find everything you need to enhance your outdoor space today.",
    keyword: "",
  },
  "Handy-Coil": {
    title: "Buy Premium Handy Coil at W-Hanson UK - Ideal for Your Projects",
    description:
      "Explore the versatile Handy Coil at W-Hanson UK. Perfect for various applications, from DIY projects to professional use.",
    keyword: "",
  },
  "Artificial-Grass": {
    title:
      "Transform Your Outdoor Space with Premium Artificial Grass- Buy Now!",
    description:
      "Enhance your garden with realistic, low-maintenance artificial grass from W-Hanson UK. Browse our range for the perfect solution to your landscaping needs.",
    keyword: "",
  },
  "TIMco-Metalwork": {
    title: "Leading Supplier of TIMco Metalwork Solutions in the UK",
    description:
      "Explore TIMco metalwork solutions at W-Hanson UK. Find durable fasteners and hardware for your projects. Discover our range today!",
    keyword: "",
  },
  "General-Shop-Materials": {
    title: "Leading Supplier of General Shop Materials - W-Hanson UK",
    description:
      "Explore a wide range of general shop materials at W-Hanson UK. Find everything you need for your shop projects and more.",
    keyword: "",
  },
  "Saw-Blades": {
    title: "High-Performance Saw Blades for Precision Cutting | W-Hanson UK",
    description:
      "Explore our range of advanced saw blades designed for durability and efficiency at W-Hanson UK. Ideal for precise cutting tasks.",
    keyword: "",
  },
  "Cleaning-Products": {
    title: "Buy Effective Cleaning Products Online - W-Hanson UK",
    description:
      "Explore a wide range of effective cleaning products at W-Hanson UK. Find solutions for all your cleaning needs, from household to industrial use.",
    keyword: "",
  },
  "PPE": {
    title: "Personal Protective Equipment (PPE) by W-Hanson UK",
    description:
      "Explore our range of personal protective equipment (PPE) at W-Hanson UK. Find high-performance gear to keep your team safe and productive.",
    keyword: "",
  },
  "Intumescent": {
    title: "High-performance Intumescent Strip Solutions at W-Hanson UK",
    description:
      "Explore W-Hanson UK's range of intumescent strip products designed to enhance fire safety and prevent smoke penetration.",
    keyword: "",
  },
  "Misc-Shop-Products": {
    title: "Shop Miscellaneous Products Online at W-Hanson UK",
    description:
      "Explore a diverse range of miscellaneous products at W-Hanson UK. Browse now for great deals on unique items!",
    keyword: "",
  },
  "Misc-Yard-Products": {
    title: "Top Selections of Yard Products Available at W-Hanson UK",
    description:
      "Explore a wide range of yard products at W-Hanson UK. Find everything you need for your outdoor projects.",
    keyword: "",
  },
  "Veto-Security": {
    title: "Secure Your Premises with Advanced Veto Security Solutions",
    description:
      "Explore W-Hanson UK's comprehensive range of Veto Security systems for enhanced protection. Discover reliable security solutions tailored for your needs.",
    keyword: "",
  },
  "Electrical": {
    title: "Leading Electrical Solutions by W-Hanson UK",
    description:
      "Explore top-tier electrical products and services at W-Hanson UK. Discover our comprehensive range for your needs today.",
    keyword: "",
  },
  "DPC": {
    title: "Expert Waterproofing Solutions for Your Construction Needs",
    description:
      "Explore innovative DPC solutions at W-Hanson UK. Find reliable products for effective moisture control in construction projects.",
    keyword: "",
  },
  "Polythene-Rolls": {
    title: "Premium Polythene Rolls from W-Hanson UK for All Needs",
    description:
      "Get top-tier polythene rolls at W-Hanson UK. Perfect for packaging and protection, our products ensure durability and performance.",
    keyword: "",
  },

}

export default metaConfig
