import React, { useState } from 'react'
import PageContainer from '../../components/layouts/PageContainer'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import Loader from '../Loader';
import BreadCrumb from '../../components/elements/BreadCrumb';
import { Input, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const Forgot = () => {

    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Login",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    const [email, setEmail] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const onHandleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("email", email);
        try {
            const response = await axios({
                method: "post",
                url: `${config.apiUrl}/forgot_password`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                if (response.data.success == true) {
                    notification.open({
                        message: (
                            <div className="d-flex">
                                <div className="pr-20">
                                    <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                                </div>
                                <div>OTP is send </div>
                            </div>
                        ),
                        description: "OTP is send to Your Email Address!",
                    });
                    localStorage.setItem('pass', response.data.otp)
                    localStorage.setItem('authid', response.data.user_id)
                    navigate("/users/otp-verification");
                } else {
                    setError(true);
                    notification.open({
                        message: (
                            <div className="d-flex">
                                <div className="pr-20">
                                    <i
                                        className="fa fa-exclamation-circle text-danger"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                </div>
                                <div>Opp! Something went wrong.</div>
                            </div>
                        ),
                        description: "Please Enter Correct Credentials",
                    });
                }
                setLoading(false);
            });
        } catch (err) {
            setLoading(false);
        }
    };





    return (
        <PageContainer header={headers} footer={footer} title="Login">
            {isLoading && <Loader />}
            <div className="ps-page--my-account">
                <BreadCrumb breacrumb={breadCrumb} />

                <div className="mb-70 mt-70 bg-white">
                    <div className="container">
                        <form className="ps-form--account pt-0" onSubmit={onHandleLogin}>
                            <div className="ps-tab active border-radius3 bd-login-page">
                                <div className="ps-form__content p-5 ">
                                    <h2 className="pb-20 text-center">Forgot Password</h2>
                                    <div className="form-group form-forgot">
                                        <Input
                                            className="form-control mb-3"
                                            type="email"
                                            placeholder="Email address"
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        {error == true ? (
                                            <span className="text-danger font-12">
                                                Please Enter Correct Email
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="form-group submit">
                                        <button
                                            type="submit"
                                            className="ps-btn ps-btn--fullwidth bg-blue color-white"
                                        >
                                            Get OTP
                                        </button>
                                    </div>
                                    <div className="mt-20 text-center">
                                        <p className="mb-0">Dont have an account?</p>
                                        <Link to="/users/registration" className="color-blue" style={{ fontWeight: "600" }}>Register Now</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </PageContainer>
    )
}

export default Forgot