import React from 'react';

const FooterCopyright = () => (
    <div className="ps-footer__copyright">
        <p className='color-white'>&copy;  2024 W.Hanson Group. All Rights Reserved</p>
        <p className='color-white'>
            Design and Developed by <a href='https://www.zithas.co.uk' target='_blank' style={{ color: "#ffb600", marginLeft: "0px", fontWeight: "700" }}>Zithas Technologies</a>
        </p>
    </div>
);

export default FooterCopyright;
