import { Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import config from "../../config";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const CartData = ({ verticalTab, IsActive }) => {

    useEffect(() => {
        if (verticalTab == 5) {
            onGetData()
        }
    }, [verticalTab])

    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [history, setHistory] = useState([])
    const [negotiated, setNegotiated] = useState(false)

    const onGetData = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('user_id', localStorage.getItem("auth_id"))
        try {
            await axios({
                method: "post",
                url: `${config.apiUrl}/userCartData`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(res => {
                setData(res.data.data.reverse())
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const [open, setOpen] = useState(false)
    const [item_details, setItemDetails] = useState([])
    const subTotal = item_details?.reduce((total, obj) => total + parseFloat(obj.price) * parseFloat(obj.quantity),
        0)


    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 10
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = data?.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(data?.length / itemsPerPage)
    const navigate = useNavigate()

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data?.length;
        setItemOffset(newOffset);
    };
    const [cartID, setCartID] = useState('')

    const onGetProducts = async (e, option, regenerate) => {
        setLoading(true);
        setCartID(e)
        const formData = new FormData();
        formData.append('cart_id', e)
        try {
            await axios({
                method: "post",
                url: `${config.apiUrl}/cartItems`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                setHistory(res.data.cart_history)
                setItemDetails(res.data.data)
                if (option) {
                    setOpen(true)
                }
                const updatedArray = res.data.data.map(item => ({
                    id: item.product_id,
                    name: item.product_name,
                    negotiate: false,
                    price: item.price,
                    qty: item.quantity,
                    width: item.width,
                    height: item.height,
                    length: item.length,
                    thumbnail_image: item.photos
                }));
                if (option == false) {
                    localStorage.setItem("items", JSON.stringify(updatedArray))
                    localStorage.setItem("cart_id", e)
                    if (regenerate == true) {
                        swal({
                            icon: "success",
                            title: "Success!",
                            text: "Products is added to your Cart",
                        }).then(data => {
                            navigate('/shopping-cart')
                        })
                    } else {
                        swal({
                            icon: "success",
                            title: "Hooray!",
                            text: "Your cart has been successfully Regenerated",
                        }).then(data => {
                            navigate('/shopping-cart')
                        })
                    }
                }
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const [reCreate, setReCreate] = useState(false)
    const [orderDate, setOrderDate] = useState('')

    const regenerateCart = async (e) => {
        setLoading(true);
        setCartID(e)
        const formData = new FormData();
        formData.append('cart_id', e)
        try {
            await axios({
                method: "post",
                url: `${config.apiUrl}/cartItems`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                const updatedArray = res.data.data.map(item => ({
                    id: item.product_id,
                    name: item.product_name,
                    negotiate: false,
                    price: item.price,
                    qty: item.quantity,
                    width: item.width,
                    height: item.height,
                    length: item.length,
                    thumbnail_image: item.photos
                }));
                localStorage.setItem("items", JSON.stringify(updatedArray))
                swal({
                    icon: "success",
                    title: "Hooray!",
                    text: "Your cart has been successfully Regenerated",
                }).then(data => {
                    navigate('/shopping-cart')
                })
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <div className="ps-page__content">
                <div className="ps-section--account-setting">
                    <div className="ps-section__header">
                        <h3>Negotaite Cart Data</h3>
                    </div>
                    <div className="ps-section__content">
                        <table role="table" className="table-striped table">
                            <thead className="">
                                <tr role="row">
                                    <th>Cart ID</th>
                                    <th className="">Date</th>
                                    <th>Amount</th>
                                    <th>Products</th>
                                    <th>Negotiation Status</th>
                                    <th className="text-center">Options</th>
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {currentItems?.map((items, i) => {
                                    var currentDate = new Date();
                                    var threeDaysAgo = new Date(currentDate);
                                    threeDaysAgo.setDate(currentDate.getDate() - 3);
                                    return (
                                        <tr role="row" key={i} className={`${IsActive ? (i == 0 ? "tbl-active" : "") : ''} position-relative`}>
                                            <td className="ant-table-cell">
                                                {i == 0 ? (
                                                    <>
                                                        {IsActive ? (
                                                            <i className="icon-arrow-right dancing-arrow"></i>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                                CRT0{items.id}
                                            </td>
                                            <td>{moment(items.created_at).format('DD-MM-YYYY')}</td>
                                            <td>{items.price != null ? "£" + items.price : "-"}</td>
                                            <td>{items.count}</td>
                                            <td>
                                                <span>
                                                    <span
                                                        className={
                                                            items.negotiated == '1'
                                                                ? "ant-tag ant-tag-green"
                                                                : "ant-tag ant-tag-volcano"
                                                        }
                                                    >
                                                        {items.negotiated == '1'
                                                            ? "Negotiated"
                                                            : "Non Negotiated"}
                                                    </span>
                                                </span>
                                            </td>
                                            <td className="border-botttom-black text-center">
                                                <a className="small-btn text-white" onClick={() => {
                                                    onGetProducts(items.id, true, true)
                                                    setOrderDate(items.created_at)
                                                }}>
                                                    <i className="icon-eye"></i>
                                                </a>
                                                <a className="small-btn text-white" onClick={() => {
                                                    if (threeDaysAgo <= new Date(items.created_at)) {
                                                        swal({
                                                            icon: "warning",
                                                            title: "Are you sure?",
                                                            text: "Do you want to add this item into Your Cart?",
                                                            buttons: true
                                                        }).then(data => {
                                                            if (data) {
                                                                onGetProducts(items.id, false, true)
                                                            }
                                                        })
                                                    } else {
                                                        setReCreate(true)
                                                        setCartID(items.id)
                                                    }
                                                }}>
                                                    <i className="icon-cart"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="mt-30">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                breakClassName={'page-item'}
                                breakLinkClassName={'ant-pagination-item-link'}
                                containerClassName={'pagination'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'ant-pagination-item-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'ant-pagination-item-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'ant-pagination-item-link'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                title="Product Details"
                centered
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                footer={<div className="footer-none"></div>}
                width={800}
            >
                <div>

                    <div className="bd-text-style">
                        <table role="table" className="table-striped table">
                            <thead className="">
                                <tr role="row">
                                    <th>Id</th>
                                    <th style={{ width: "61%" }}>Product Name</th>
                                    <th>Qty</th>
                                    <th>Unit Price</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {item_details?.map((obj, i) => {
                                    return (
                                        <tr role="row" key={i}>
                                            <td >
                                                {obj.product_id}
                                            </td>
                                            <td>{obj.product_name}</td>
                                            <td>{obj.quantity}</td>
                                            <td>£{parseFloat(obj.price)}</td>
                                            <td>
                                                £{(parseFloat(obj.price) * parseFloat(obj.quantity)).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="row justify-space-between w-100 mt-5">
                            <div className="col-7">
                                <div className="mb-5">
                                    <h3>Update Price History</h3>
                                    <div>
                                        <ul style={{ listStyle: "none" }} className="pl-0">{history?.map((obj, i) => {
                                            return (
                                                <li className={history[history.length - 1].id == obj.id ? "font-blue" : ""} key={i}><i className='icon-arrow-right pe-3'></i> £{obj.message} at {moment(obj.date).format('DD-MM-YYYY h:mm:ss')}
                                                    {history[history.length - 1].id == obj.id ? " and this is Final Price" : ""}</li>
                                            )
                                        })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="bt-main-total row">
                                <div className="col-8 pr-0 pl-0">
                                    <div className="mb-05 btm-line">Grand Total</div>
                                </div>
                                <div className="col-4 pl-0 pr-0 text-right">
                                    <div className="mb-05 btm-line">£{parseFloat(subTotal).toFixed(2)}</div>
                                </div>
                                <div className='mt-2 text-right'>
                                    <button class="ps-btn bg-blue color-white"
                                        onClick={() => {
                                            var currentDate = new Date();
                                            var threeDaysAgo = new Date(currentDate);
                                            threeDaysAgo.setDate(currentDate.getDate() - 3);
                                            if (threeDaysAgo <= new Date(orderDate)) {
                                                swal({
                                                    icon: "warning",
                                                    title: "Are you sure?",
                                                    text: "Do you want to add this item into Your Cart?",
                                                    buttons: true
                                                }).then(data => {
                                                    if (data) {
                                                        onGetProducts(cartID, false, true)
                                                    }
                                                })
                                            } else {
                                                setReCreate(true)
                                            }
                                        }} >Add To Cart</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={reCreate}
                title="Negotiation Warning"
                centered
                onOk={() => setReCreate(false)}
                onCancel={() => setReCreate(false)}
                footer={<div className="footer-none"></div>}
            >
                <ol className="pl-20  mb-30">
                    <li className="mb-10">
                        Negotiation date has been exceeded, please click to Regenerate cart
                    </li>
                </ol>
                <div className="text-center mb-20 ">
                    <a
                        className="btn-ligth-blue p-4 mr-10"
                        onClick={() => setReCreate(false)}
                    >
                        Back to Shopping
                    </a>
                    <a className="btn-blue p-4 ml-10" onClick={() => regenerateCart(cartID)}>
                        Regenerate Your Cart
                    </a>
                </div>
            </Modal>


        </div>
    );
};

export default CartData;
