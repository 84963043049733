import React, { useEffect } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import PEFC from "../../assets/static/img/Ironbridge PEFC_August 2022.pdf";
import FSC from "../../assets/static/img/Ironbridge FSC_August 2022.pdf";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const ChainofCustody = () => {

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Chain of Custody",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;
  return (
    <div>
      <Helmet>
        <title>Ensure Transparency with W-Hanson UK's Chain of Custody</title>  
        <meta NAME="Description" Content="Explore W-Hanson UK's reliable chain of custody for trustworthy sourcing and secure operations." />

      </Helmet>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-section--custom">
            <div className="container">
              <div className="ps-section__header">
                <div className="ps-section--vendors term-section bd-privacy">
                  <div className="ps-section__header mb-0 pb-0 mt-20">
                    <h3 style={{fontSize:"30px"}}>Chain of Custody</h3>
                  </div>
                  <p className="mt-30">
                    <strong>W.Hanson (Iron Bridge) Ltd </strong> has accreditation, through  <strong>Forest Management Certification  </strong>, for our own  <strong>'Chain of Custody'</strong> for both FSC® (W.Hanson (IB) Ltd - FSC-C007970 PEFC/16-37-1746 products. This allows to obtain and sell on to our customers, materials that have the highest level of integrity.
                  </p>
                  <p>
                    Austrian, German, Finnish and Scandanavian forests lead the way in terms of environmental management, from where a high proportion of <strong>PEFC/16-37-1746 (Programme for the Endorsement of Forest Certification schemes) </strong> certified timber products are manufactured. The W.Hanson Group purchase softwood mouldings & PSE, softwood linings, CLS and Spruce from these countries, and with our <strong> 'Chain of Custody' </strong>can verify their <strong>PEFC/16-37-1746  </strong>certification from point of purchase through to point of sale.
                  </p>
                  <div className="mt-40 mb-5">
                    <div className="d-flex justify-space-between">
                      <h2 className="col-7 pl-0">
                        W Hanson Ironbridge Ltd - FSC-C007970
                      </h2>
                      <a className="btn-blue" href={FSC} download>
                        Ironbridge FSC 2022.pdf
                      </a>
                    </div>
                    <div className="d-flex justify-space-between mt-3">
                      <h2 className="col-7 pl-0">
                        W Hanson Ironbridge Ltd  - PEFC/16-37-1746
                      </h2>
                      <a className="btn-blue" href={PEFC} download>
                        Ironbridge PEFC 2022.pdf
                      </a>
                    </div>
                  </div>
                  <p> Likewise, <strong>FSC® (Forest Stewardship Council®)  </strong> certification covers products such as MDF, MDF mouldings, OSB and T&G Chipboard flooring. Again our <strong>'Chain of Custody' </strong> can verify their <strong>FSC® </strong>certification from point of purchase through to point of sale.</p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default ChainofCustody;
