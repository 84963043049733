import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/layouts/PageContainer'
import BreadCrumb from '../../components/elements/BreadCrumb'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { notification } from 'antd';
import Loader from '../Loader';
import config from '../../config';

const Career = () => {
    const navigate = useNavigate();
    // useEffect(() => {
    //     if (
    //         !localStorage.getItem("auth_user")
    //     ) {
    //         navigate("/login");
    //     }
    // }, []);

    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Career",
        },
    ];


    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    const formRef = useRef(null)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [position, setPosition] = useState('')
    const [description, setDescription] = useState('')
    const [ctc, setCtc] = useState('')
    const [file, setFile] = useState('')
    const [isLoading, setLoading] = useState(false)

    const onGetinTouch = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("description", description);
        formData.append("position", position);
        formData.append("file", file);
        formData.append("ctc", ctc);
        try {
            const response = await axios({
                method: "post",
                url: `${config.apiUrl}/add_career`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                notification.open({
                    message: (
                        <div className="d-flex">
                            <div className="pr-20">
                                <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                            </div>
                            <div>Thank you for visiting Whanson Group</div>
                        </div>
                    ),
                    description: "Our Team Will Contact You Soon",
                });
                setLoading(false);
                setName("");
                setPhone("");
                setEmail("");
                setCtc("");
                setPosition('')
                setDescription("");
                setFile('')

            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <Helmet>
                <title>
                    Explore Exciting Career Opportunities at W-Hanson UK
                </title>
                <meta
                    name="description"
                    Content="Discover rewarding job openings at W-Hanson UK. Join us in shaping the future of [industry/sector]. Apply today!"
                />
            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <img src="https://media.istockphoto.com/id/644335608/photo/personal-development-self-improvement-and-career-growth.jpg?b=1&s=170667a&w=0&k=20&c=V48HbKg-U9iMxOWkyg_G6Kx4MxxS7yg9K69pNd1vhiQ=" height="300" style={{ width: "100%", objectFit: "cover", }} alt="" />
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-section--custom pb-0">
                        <div className="container ">
                            <div className='row justify-content-center pt-90 pb-70'>
                                <div className='col-10 text-center career-text mobile-career'>
                                    <h5>Job Vacancies</h5>
                                    <h1 className='mb-5'>
                                        Examine Potential, Develop Your Career, Design Your Professional Path, and Move Forward: Jobs at Whanson Group For Career Opportunities
                                    </h1>
                                    <p>As we embark on our journey towards innovation and excellence, we invite passionate and talented individuals to join our dynamic team, where collaboration, creativity, and a commitment to making a positive impact converge to create a workplace that fosters personal growth, professional development, and the pursuit of shared goals, shaping not only careers but a collective vision for a brighter and more impactful future.</p>

                                    <div className="form-group submit mt-5" ref={formRef}>
                                        <button
                                            type="submit"
                                            className="ps-btn ps-btn--fullwidth bg-blue color-white col-4 bd-join"
                                            onClick={() => formRef?.current?.scrollIntoView({ behavior: 'smooth' })}
                                        >
                                            Join Us Now
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='form-bg-blue'>
                            <div className='container'>
                                <div className=' row justify-content-center pt-50 pb-50' >
                                    <form className="ps-form--contact-us col-md-9 career-form" onSubmit={onGetinTouch}>
                                        <h1 className='text-center mb-5 pb-5'>Fill the Form below for Indicating Your Potentails</h1>
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Name *"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        placeholder="Enter Email *"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Phone Number *"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Position *"
                                                        value={position}
                                                        onChange={(e) => setPosition(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Expected CTC *"
                                                        value={ctc}
                                                        onChange={(e) => setCtc(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        style={{ paddingTop: '14px' }}
                                                        onChange={(e) => setFile(e.target.files[0])}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control"
                                                        rows="5"
                                                        placeholder="Write a Description"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        required
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group submit ">
                                            <button className="ps-btn bg-blue color-white">Send message</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer >
        </div >
    )
}

export default Career